import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PopperItem } from "@components/common/Popper";
import { MenuActionFixed } from "../AssistantQuestion";
import { PopperChatFeedback } from "../ChatFeedback";
import _ from "lodash";

//classnames
import classNames from 'classnames';

// context
import { useScreenSize } from '@context/ScreenSizeContext';

// Components
import LockView from "./LockView";

//actions
import { setTypeChatActive } from "@actions";

// Image
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";
import imgUser from '@images/top/user.svg';

const AssistantAvatarSmall = (props) => {
  // Destructure props
  const {
    stateType,
    handleItemClick,
    isFullScreenViewWithChat,
    alertPendingQuestion,
    handleCheckVirtualPreceptorStatus
  } = props && props;


  // Extract data from props
  const dispatch = useDispatch();

  // Lấy dữ liệu Case từ Redux store
  const caseStore = useSelector((state) => state.cases || []);
  const caseData = caseStore?.data?.Case;
  const Interface = caseData?.Interface || {};
  const PatientAvatar = Interface?.PatientAvatar;
  const InteractiveItems = Interface?.InteractiveItems || [];
  const InstantChatFeedbackOption = caseData?.ChatSettings?.InstantChatFeedbackOption;
  const PreceptorAvatar = Interface?.PreceptorAvatar;
  const hasChatTask = _.some(InteractiveItems, { 'Task': 'chat' });
  let CurrentFrontSessionId = caseData?.Session?.CurrentFrontSessionId;
  // Use screen size context
  let { isMobile } = useScreenSize();

  // Get the question data and loading state from the Redux store
  const questionData = useSelector((state) => state.question || {});
  const isQuestionLoading = questionData?.isLoading;
  let hasQuestion = questionData?.data?.Question !== null;

  //Check Lock Elements
  const isChatPending = useSelector((state) => state.isChatPending || {});
  const isLock = isChatPending?.isLock;

  // Check missing task
  const missingTaskStatus = useSelector((state) => (state?.checkMissingTask?.data) || {});
  const isPendingQuestion = missingTaskStatus?.status === "PendingQuestion";

  //state
  const [isQuestionPopperVisible, setQuestionPopperVisible] = useState(false);

  // Define image paths
  const imagePaths = [
    {
      path: PreceptorAvatar || imageInstructor,
      type: "question",
      isShow: true,
      fullName: "Instructor"
    },
    {
      path: PatientAvatar,
      type: "chat",
      isShow: hasChatTask,
      fullName: "Patient"
    }
  ];

  // Define zIndexValue
  const zIndexValue = isFullScreenViewWithChat ? '16000' : '10';

  // Set default active type when the component mounts
  useEffect(() => {
    const activeType = Object.keys(stateType).find(key => stateType[key]);
    if (activeType) {
      dispatch(setTypeChatActive(activeType));
    }
  }, [dispatch, stateType]);

  //Check Pending Question
  useEffect(() => {
    setQuestionPopperVisible(true);
  }, [alertPendingQuestion, hasQuestion]);

  // Handle closing the Popper
  const closePopper = () => {
    setQuestionPopperVisible(false);
  };

  // Render list item function
  const renderListItem = (item, index) => {
    const isActiveTask = stateType[item.type];
    const visiblePatient = `${item?.isShow ? "d-flex" : "d-none"}`
    const listItemClasses = `list-assistant__item position-relative flex-column ${visiblePatient} ${isActiveTask ? "active" : ""}`;

    const itemStyles = {
      backgroundImage: `url(${item.path || imgUser})`,
      cursor: 'pointer',
      pointerEvents: isActiveTask || isQuestionLoading ? "none" : "auto"
    };
    const popperStyles = {
      common: {
        position: 'absolute',
        minHeight: '30px',
        padding: '5px',
        minWidth: 'auto',
      },
      question: {
        desktop: {
          left: '100%',
          bottom: '20%',
          marginLeft: '20px',
          minWidth: '253px',
        },
        mobile: {
          left: '-100%',
          padding: '14px 12px',
          minWidth: '189px',
          top: '71px'
        },
      },
      feedback: {
        left: {
          left: '100%',
          top: '0px',
          marginLeft: '20px',
          minWidth: '568px',
        },
      },
    };

    const questionPopperStyle = {
      ...popperStyles.common,
      ...(isMobile ? popperStyles.question.mobile : popperStyles.question.desktop),
    };

    const isInstructorAvatar = !stateType.question && item.type === "question";
    const logicWhenPendingQuestionTooltip = isPendingQuestion || hasQuestion;
    const itemHasQuestionPending = classNames("list-assistant__item--image", {
      pulse: logicWhenPendingQuestionTooltip && isInstructorAvatar
    });
    return (
      <div className={listItemClasses} type-item={item.type} key={index}>
        {isLock && <LockView {...props} />}
        <div
          className={itemHasQuestionPending}
          lock-type={item.type}
          style={itemStyles}
          onClick={() => {
            // Kiểm tra nếu active thì dừng sự kiện click
            if (isActiveTask) return;
            
            handleItemClick(item.type);
            dispatch(setTypeChatActive(item.type));
            item.type === "question" && handleCheckVirtualPreceptorStatus(CurrentFrontSessionId);
          }}
        >
          {logicWhenPendingQuestionTooltip && isInstructorAvatar &&
            <div className="active-dot"></div>
          }
        </div>
        <div className={`list-assistant__item--name ${isMobile ? "text-white" : ""}`}>{item?.fullName}</div>
        {logicWhenPendingQuestionTooltip && isQuestionPopperVisible && isInstructorAvatar && (
          <PopperItem
            content={`You have an unanswered question from the Instructor...`}
            styleInline={questionPopperStyle}
            postition={isMobile ? "up-left" : "left"}
            isOpen={isQuestionPopperVisible}
            icon={null}
            handleClosePopper={closePopper}
            buttonClose={true}
            divElement={`${isInstructorAvatar && isMobile ? "tooltip-instructor-mobile" : ""}`}
          />
        )}

        {!isMobile && isInstructorAvatar && InstantChatFeedbackOption && (
          <PopperChatFeedback
            {...props}
            styleInline={{
              ...popperStyles.common,
              ...popperStyles.feedback.left,
            }}
            postition="left-top"
          />
        )}
      </div>
    );
  };

  return (
    <div style={{ zIndex: zIndexValue }} id="fixed-list-assistant" className={`${isFullScreenViewWithChat ? "show-fixed" : "hide-fixed"} ${isMobile ? 'is-mobile' : ''}`}>
      <div className="list-assistant">
        {imagePaths?.map((item, index) => renderListItem(item, index))}
      </div>
      {!isMobile && <MenuActionFixed {...props} />}
    </div>
  );
}

export default AssistantAvatarSmall;