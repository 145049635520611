import React from 'react';

//boostrap
import { Modal, ModalBody } from "reactstrap";

// Icons
import IconComingSoon from '@images/coin/icon-coming-soon.png';

//components
import { ButtonCloseModal } from "@components/common/Modal";

function ModalPurchaseCoinItComingSoon(props) {
  const { isOpen, toggle } = props && props;
  return (
    <Modal
      className="modal-custom modal-size-lg"
      zIndex={16000}
      isOpen={isOpen}
      backdrop={true}
      centered
      backdropClassName="modal-backdrop-custom"
      modalTransition={{ timeout: 700 }}
      toggle={toggle}
    >
      <ButtonCloseModal {...props} handleCloseModal={toggle} />
      <ModalBody>
        <div className="coin-modal">
          <img className='coin-modal__baner' src={IconComingSoon} alt="Coin Received" />
          <h3 className='coin-modal__heading'>Feature Coming Soon...</h3>
          <p className='coin-modal__desc mt-0'>
            This feature is currently under development and will be available soon. Please check back later or contact us at <a href="mailto:contact@medl2ab.com" target='_blank' rel="noreferrer">contact@med2lab.com</a> for assistance.
          </p>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalPurchaseCoinItComingSoon;