export const advanceConstants = {
    ADVANCE_REQUEST: 'ADVANCE_REQUEST',
    ADVANCE_SUCCESS: 'ADVANCE_SUCCESS',
    ADVANCE_FAILURE: 'ADVANCE_FAILURE',
    
    UPDATE_CASE_ADVANCE_REQUEST: 'UPDATE_CASE_ADVANCE_REQUEST',
    UPDATE_CASE_ADVANCE_SUCCESS: 'UPDATE_CASE_ADVANCE_SUCCESS',
    UPDATE_CASE_ADVANCE_FAILURE: 'UPDATE_CASE_ADVANCE_FAILURE',

    CASE_COMPLETE_REQUEST: 'CASE_COMPLETE_REQUEST',
    CASE_COMPLETE_SUCCESS: 'CASE_COMPLETE_SUCCESS',
    CASE_COMPLETE_FAILURE: 'CASE_COMPLETE_FAILURE',
    
    AUTO_NEXT_TIMEPOINT_REQUEST: 'AUTO_NEXT_TIMEPOINT_REQUEST',
    AUTO_NEXT_TIMEPOINT_SUCCESS: 'AUTO_NEXT_TIMEPOINT_SUCCESS',
    AUTO_NEXT_TIMEPOINT_FAILURE: 'AUTO_NEXT_TIMEPOINT_FAILURE',
};
