import { wizardConstants } from "@constants";

export function loadWizard(state = {}, action) {
  switch (action.type) {
    case wizardConstants.LOAD_WIZARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case wizardConstants.LOAD_WIZARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case wizardConstants.LOAD_WIZARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadNextStepWizard(state = {}, action) {
  let dataReference
  let dataMedia
  switch (action.type) {
    case wizardConstants.LOAD_NEXT_STEP_WIZARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case wizardConstants.LOAD_NEXT_STEP_WIZARD_SUCCESS:
      if (action.params === "reference_source") {
        dataReference = action.payload
      } else if (action.params === "use_media") {
        dataMedia = action.payload
      }
      return {
        ...state,
        data: action.payload,
        dataReference,
        dataMedia,
        isLoading: false,
      };
    case wizardConstants.REMOVE_NEXT_STEP_REFERENCE_SUCCESS:
      dataReference = action.payload
      return {
        ...state,
        data: action.payload,
        dataReference,
        dataMedia,
        isLoading: false,
      };

    case wizardConstants.REMOVE_NEXT_STEP_MEDIA_SUCCESS:
      dataMedia = action.payload
      return {
        ...state,
        data: action.payload,
        dataReference,
        dataMedia,
        isLoading: false,
      };
    case wizardConstants.LOAD_NEXT_STEP_WIZARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function fluxImageGeneration(state = {}, action) {
  switch (action.type) {
    case wizardConstants.IMAGE_GENERATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case wizardConstants.IMAGE_GENERATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case wizardConstants.IMAGE_GENERATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
