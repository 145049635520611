import React, { useEffect, useState } from 'react';
import { LoaderText } from "@utils";

//react-router-dom
import { useHistory } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

// components
import { HomeLearnMain, ModaEnrollmentSuccessful } from "@components/learner/Home";

//constants
import { getPortfolioInfo } from "@actions";

// react-redux
import { useSelector } from "react-redux";

function LearnHomePage(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getPortfolioInfo());
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // State to keep track of whether to show the modal or not
    const [modal, setModal] = useState({
        weitzmanEnrollment: false
    });

    useEffect(() => {
        // Check the URL for the "weitzman-confirm" parameter
        const urlParams = new URLSearchParams(window.location.search);
        const confirmParam = urlParams.get('weitzman-confirm');

        // If the "weitzman-confirm" parameter is present, set weitzmanEnrollment to true
        if (confirmParam === 'true') {
            setModal({ ...modal, weitzmanEnrollment: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = () => {
        setModal({ ...modal, weitzmanEnrollment: !modal.weitzmanEnrollment });
        history.push("/learn/home");
    };

    const portfolioReducer = useSelector((state) => state?.portfolio || {});
    return (
        <div className={`${portfolioReducer?.isLoading ? 'vh-100' : ''} position-relative`}>
            <div className="home-learner-wrap">
                {portfolioReducer?.isLoading && (
                    <LoaderText {...props} />
                )}

                {!portfolioReducer?.isLoading && (
                    <HomeLearnMain
                        {...props}
                    />
                )}
            </div>

            {/* Conditionally render the modal based on the state */}
            {modal.weitzmanEnrollment && <ModaEnrollmentSuccessful toggle={toggle} isOpen={modal.weitzmanEnrollment} />}
        </div>
    );
}

export default LearnHomePage;