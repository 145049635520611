import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextAreaCommon,
  UploadCommon,
  ChoiceCommon,
  ReviewCommon,
  SelectListCommon,
  SelectListCommonImage,
} from "@components/teachV2/performanceReport/listReportCommon";
import { loadWizard } from "@actions";
import { useSelector, useDispatch } from "react-redux";
import { StepComponent, ModalRegenerate } from "@components/teachV2/performanceReport";
import ResultGenerate from "../../components/teachV2/home/result/ResultGenerate";
import { wizardConstants } from "@constants";
import { Button } from "reactstrap";
import Icon from '@components-common/Icon'
import Reload from '@images/common/reload.svg'
import { useHistory } from "react-router-dom";
import McqWaiting from '@images/teachV2/waiting-mcq.gif'
// import GenrateFail from '@images/teachV2/gen-failed.gif'

const PerformanceReport = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const dataWizard = useSelector((state) => state.loadWizard || {});
  const [step, setStep] = useState(1);
  const [contentLast, setContentLast] = useState();
  const [loadingResult, setLoadingResult] = useState(false)
  const [modal, setModal] = useState({
    ModalRegenerate: false
  })
  const [dataContentFile, setDataContentFile] = useState()

  const toggle = (type) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  }

  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  useEffect(() => {
    dispatch(loadWizard(1));
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_REFERENCE_SUCCESS, payload: null });
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_MEDIA_SUCCESS, payload: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { 
    register, 
    handleSubmit, 
    setValue, 
    watch, 
    control,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    let defaultValues = {};
    dataWizard?.data?.Params?.forEach((params) => {
      const { name, default_value } = params;
      defaultValues[name] = default_value
    })
    reset({ ...defaultValues });
  }, [reset, dataWizard])

  const onSubmit = (data) => console.log(data);

  const selectTypeFlow = (contents, parent) => {
    switch (contents?.view) {
      case "simple_form":
        return (
          <TextAreaCommon
            step={step}
            register={register}
            handleNext={handleNext}
            handleBack={handleBack}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            parent={parent}
            selectTypeFlow={selectTypeFlow}
            control={control}
          />
        )
      case "select_card":
        return (
          <ChoiceCommon
            step={step}
            register={register}
            handleNext={handleNext}
            handleBack={handleBack}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            parent={parent}
            getValues={getValues}
            selectTypeFlow={selectTypeFlow}
          />
        )
      case "select_from_list":
        return (
          contents?.data_source === "media_library" ? (
            <SelectListCommonImage
              step={step}
              register={register}
              handleNext={handleNext}
              handleBack={handleBack}
              watch={watch}
              setValue={setValue}
              title={contents?.title}
              subtitle={contents?.subtitle}
              dataForm={contents}
              parent={parent}
              getValues={getValues}
              selectTypeFlow={selectTypeFlow}
            />
          ) : (
            <SelectListCommon
              step={step}
              register={register}
              handleNext={handleNext}
              handleBack={handleBack}
              watch={watch}
              setValue={setValue}
              title={contents?.title}
              subtitle={contents?.subtitle}
              dataForm={contents}
              parent={parent}
              getValues={getValues}
              selectTypeFlow={selectTypeFlow}
            />
          )
        )  
      case "upload_file":
        return (
          <UploadCommon
            step={step}
            register={register}
            handleNext={handleNext}
            handleBack={handleBack}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            parent={parent}
            getValues={getValues}
            selectTypeFlow={selectTypeFlow}
          />
        )
      case "review":
        return (
          <ReviewCommon
            step={step}
            register={register}
            handleNext={handleNext}
            handleBack={handleBack}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            parent={parent}
            getValues={getValues}
            selectTypeFlow={selectTypeFlow}
            setLoadingResult={setLoadingResult}
            loadingResult={loadingResult}
            setDataContentFile={setDataContentFile}
            dataContentFile={dataContentFile}
            setContentLast={setContentLast}
          />
        )
      default:
        break;
    }
  }

  const handleNewRegenerate = () => {
    dispatch(loadWizard(1));
    setDataContentFile()
    setStep(1)
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_REFERENCE_SUCCESS, payload: null });
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_MEDIA_SUCCESS, payload: null });
  }

  return (
    <div className="wrapper -performancePage">
      {loadingResult ? (
        <>
          <StepComponent dataWizardDefault={dataWizard?.data} step={step}/>
          <div className="loading-generate">
            <img src={McqWaiting} alt="mcq" />
            <div className="loading-generate__content">
              <h1>Generating Your Content</h1>
              <p>Just a moment... Our AI is preparing your tailored <br /> content.</p>
            </div>
          </div>
        </>
      ) : (
        !dataContentFile ? (
          <>
            <StepComponent dataWizardDefault={dataWizard?.data} step={step}/>
            <form className="form-report" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-report__question">
                {dataWizard?.data?.Flow?.map((flow, index) => (
                  (index + 1) === step && selectTypeFlow(flow?.Contents, flow)
                ))}
              </div>
            </form>
          </>
        ) : (
          <div className="form-report">
            <div className="form-report__question">
              {/* <div className="form-area">
                <div className="form-area__content">
                  <div className="d-flex justify-content-center">
                    <img src={GenrateFail} alt="Oops" />
                  </div>
                  <p>Oops! Something went wrong, it look like there are no questions generated at the moment. <br /> Please re-generate the MCQ</p>
                </div>
              </div> */}
              <div className="form-area">
                <div className="form-area__content">
                  <h1>Your Multiple Choice Questions Are Ready!</h1>
                  <p>Here are {watch("number_questions") || 4} questions generated based on a similar concept from your input. Save your favorites to the Question Bank by clicking the save icon for later review and editing. You can also copy them for immediate use or provide feedback to help improve future generations.</p>
                </div>
              </div>
              <ResultGenerate dataContentFile={dataContentFile} loadingResult={loadingResult} />
              <div className="btn-result d-flex align-items-center justify-content-end">
                <Button
                  className="d-flex align-items-center"
                  onClick={() => toggle('ModalRegenerate')}
                  color='secondary'
                  disabled={loadingResult}
                >
                  <Icon src={Reload} width={20} height={17}/>
                  <span className="ml-1">
                    Re-generate New Versions
                  </span>
                </Button>
                <Button
                  onClick={() => history.push('/teach/inventory')}
                  color='primary'
                  disabled={loadingResult}
                >
                  Visit the Question Bank 
                </Button>
              </div>
            </div>
          </div>
        )
      )}
      {modal?.ModalRegenerate && (
        <ModalRegenerate 
          isOpen={modal?.ModalRegenerate}
          toggle={() => toggle('ModalRegenerate')}
          setValue={setValue}
          watch={watch}
          item={dataWizard?.data?.Flow}
          handleNewRegenerate={handleNewRegenerate}
          setLoadingResult={setLoadingResult}
          setDataContentFile={setDataContentFile}
          dataContentFile={dataContentFile}
          getValues={getValues}
          dataForm={contentLast}
        />
      )}
    </div>
  );
};

export default PerformanceReport;
