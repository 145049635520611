import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import imgUser from '@images/top/user.svg';

const ProfileTopMenu = ({ toggleMenu }) => {
    // Get profile information from the Redux store
    const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data || {});
    const AccountData = AccountInfoData?.Account || {};
    const { profile_photo_url: profilePhoto, email } = AccountData;

    //Role Active
    const currentRole = useSelector((state) => state?.statusRoleSystem || {});
    const activeRole = currentRole?.roleActive;

    // Use profilePhoto if available, otherwise use the fallback image
    const avatarSrc = profilePhoto || imgUser;

    return (
        <ProfileContainer>
            <ProfileInfo>
                <Avatar backgroundImage={`url(${avatarSrc})`} />
                <Info>
                    <Email>{email}</Email>
                    <Role>
                        Current role: <RoleSpan>{activeRole === 'instructor' ? "Instructor" : "Learner"}</RoleSpan>
                    </Role>
                </Info>
            </ProfileInfo>
            <ButtonClose onClick={toggleMenu}>
                <CloseIcon />
            </ButtonClose>
        </ProfileContainer>
    );
};

const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D0D5DD;
    padding: 12px 16px;
    justify-content: space-between;
`;

const ProfileInfo = styled.div`
    display: flex;
    align-items: center;
`;

const Avatar = styled.div`
    border-radius: 50%;
    background: ${({ backgroundImage }) =>
        `${backgroundImage} center / cover no-repeat`};
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const Info = styled.div`
    margin-left: 12px;
`;

const Email = styled.div`
    color: var(--error-gray-900);
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
`;

const Role = styled.div`
    color: var(--gray-500);
    font-size: 12px;
    font-weight: 400;
`;

const RoleSpan = styled.span`
    color: #006E9B;
    font-weight: 500;
`;

const ButtonClose = styled.button`
    border-radius: 50%;
    background: #F2F4F7;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #000;
`;

const CloseIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5L5 15" stroke="#1D2939" strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 15L5 5" stroke="#1D2939" strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default ProfileTopMenu;