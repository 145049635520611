import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

//components
import { UserMessage, InstructorMessage } from '@components/learner/StandardScreen/Chat/Preceptor';
//loading
import { LoadingMessage } from "../Preceptor";

// Styled-component for the fade-in effect
const MessageWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease;

  &.fadeIn {
    opacity: 1;
  }
`;

function Framechat(props) {
    //props
    const { chatRecord, messagesEndRef, isLoading, heightFooterChat } = props;

    //state
    const [calculatedHeight, setCalculatedHeight] = useState(heightFooterChat || 0);
    const [fadeInIndexes, setFadeInIndexes] = useState([]);

    // Update height based on chat record
    useEffect(() => {
        setCalculatedHeight(heightFooterChat);
    }, [heightFooterChat]);

    // Set fade-in effect for messages
    useEffect(() => {
        const newFadeInIndexes = chatRecord.map((_, index) => index);
        setFadeInIndexes(newFadeInIndexes);
    }, [chatRecord]);

    const heightBottom = `calc(100vh - ${calculatedHeight}px)`;

    return (
        <MessageWrapper className="messengerCt scrollbarStyle fadeIn" ref={messagesEndRef} style={{ height: heightBottom }}>
            <div className="messengerCt__messages">
                {chatRecord?.map((value, index) => (
                    <React.Fragment key={index}>
                        <MessageWrapper className={fadeInIndexes.includes(index) ? "fadeIn" : ""}>
                            {value?.Role === "Instructor" && (
                                <InstructorMessage
                                    {...props}
                                    index={index}
                                    value={value}
                                />
                            )}

                            {value?.Role === "User" && (
                                <UserMessage
                                    {...props}
                                    value={value}
                                />
                            )}
                        </MessageWrapper>

                        {/* Loading Message at the End of Chat */}
                        {chatRecord?.length - 1 === index && (isLoading?.streamData || isLoading?.submitQuestion) && (
                            <LoadingMessage {...props} />
                        )}
                    </React.Fragment>
                ))}
            </div>
        </MessageWrapper>
    );
}

export default Framechat;