import React, { Fragment } from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import { Input } from 'reactstrap';
import { TitleNoteCommon } from '../../home/generate';
import { Controller } from 'react-hook-form';
import Select from "react-select";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function TextAreaCommon(props) {
  const { watch, setValue, title, subtitle, dataForm , control } = props;

  return (
    <WrapCommon>
      <div className="form-area">
        <div className="form-area__content">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        <div className="form-area__input">
          {dataForm?.items?.map((item, index) => (
            item?.type === "textarea" ? (
              <Input
                key={index}
                type={item?.type}
                name={item?.field}
                value={watch(item?.field)}
                placeholder={item?.placeholder}
                style={{ height: '160px' }}
                onChange={(e) => {
                  setValue(item?.field, e.target.value);
                }}
              />
            ) : (
              <Fragment key={index}>
                <TitleNoteCommon 
                  dataFor={'template'} 
                  title={'Template'} 
                  content=""
                />
                <Controller
                  control={control}
                  name={item?.field}
                  defaultValue={item?.options[0].value}
                  render={({ field: { onChange } }) => (
                    <>
                      <Select
                        className="react-select-common w-100"
                        placeholder="Select Modal"
                        value={item?.options?.find((c) => c.value === watch(item?.field))}
                        options={item?.options}
                        onChange={val => {
                          setValue(item?.field, val.value)
                          onChange(val.value)
                        }}
                        classNamePrefix="react-select-custom"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </>
                  )}
                />
              </Fragment>
            )
          ))}
        </div>
      </div> 
      <div className='btn-form'>
        <ButtonSubmitCommon {...props} dataPass={!watch(dataForm?.items[0]?.field)}/>
      </div>     
    </WrapCommon>
  )
}
