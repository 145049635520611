import React from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import { TitleNoteCommon } from '../../home/generate';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function ReviewCommon(props) {
  const { watch, title, subtitle, dataForm } = props;
  
  return (
    <WrapCommon>
      <div className="form-area">
        <div className="form-area__content">
          <h1>{title || "Title name"}</h1>
          <p>{subtitle || "Please provide a detailed request for generating content tailored to medical education"}</p>
        </div>
        <div className="form-area__review">
          {dataForm?.items?.map((item, index) => (
            item?.value_data === "document_id" || item?.value_data === "media_item_id" ? (
              watch(item?.value_data) && 
                <div className='wrap-content' key={index}>
                  <TitleNoteCommon 
                    dataFor={item?.title} 
                    title={item?.title} 
                  />
                  {watch(item?.value_data)?.map((data, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`document-text`} 
                        dangerouslySetInnerHTML={{ __html: data?.file_name }} 
                      />
                    )
                  })}
                </div>
            ) : (
              watch(item?.value_data) && (
                <div className='wrap-content' key={index}>
                  <TitleNoteCommon 
                    dataFor={item?.title} 
                    title={item?.title} 
                  />
                  <div
                    className={`${item?.value_data === "document_file" ? "document-text" : ""}`} 
                    dangerouslySetInnerHTML={{ __html: item?.value_data === "document_file" ? watch(item?.value_data)?.name : watch(item?.value_data) }} />
                </div>
              )
            )
          ))}
        </div>
      </div> 
      <div className='btn-form'>
        <ButtonSubmitCommon {...props}/>
      </div>     
    </WrapCommon>
  )
}
