import React from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import { Input } from 'reactstrap';
import { TitleNoteCommon } from '../../home/generate';
import { Controller } from 'react-hook-form';
import { listAspectRatio } from '@utils';
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fluxImageGeneration } from "@actions"

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function GenerateImage(props) {
  const { title, subtitle, watch, setValue, control } = props;
  const imageGenerated = useSelector((state) => (state.fluxImageGeneration) || []);
  const dispatch = useDispatch();

  const handleShowImg = () => {
    dispatch(fluxImageGeneration({
      action_type: "flux_drawing",
      description: watch("content"),
      is_pro: true,
      aspect_ratio: watch("aspect_ratio")
    }))
  }

  return (
    <WrapCommon>
      <div className="form-area">
        <div className="form-area__content">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        {!imageGenerated?.data ? (
          <div className="form-area__generate">
            <div className='wrap-option'>
              <TitleNoteCommon 
                dataFor={'description'} 
                title={'Description'} 
                content="Description"
              />
              <Input
                type='textarea'
                name='content'
                placeholder='A 18 year old Asian Female P'
                style={{ height: '130px' }}
                onChange={(e) => {
                  setValue("content", e.target.value);
                }}
              />
            </div>
            <div className='wrap-option'>
              <TitleNoteCommon 
                dataFor={'aspect'} 
                title={'Aspect Ratio'} 
                content="Aspect"
              />
              <Controller
                control={control}
                name="aspect_ratio"
                defaultValue={listAspectRatio[0]?.value}
                render={({ field: { onChange } }) => (
                  <>
                    <Select
                      className="react-select-common w-100"
                      placeholder="Select Modal"
                      value={listAspectRatio?.find((c) => c.value === watch("aspect_ratio"))}
                      options={listAspectRatio}
                      onChange={val => {
                        setValue("aspect_ratio", val.value)
                        onChange(val.value)
                      }}
                      classNamePrefix="react-select-custom"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </>
                )}
              />
            </div>
          </div>
        ) : (
          <div className="form-area__image">
            <img src={imageGenerated?.data} alt="default" />
          </div>
        )}
      </div> 
      <div className='btn-form'>
        <ButtonSubmitCommon {...props} handleShowImg={handleShowImg} isGenerate={!imageGenerated?.data} dataPass={watch("content")}/>
      </div>     
    </WrapCommon>
  )
}
