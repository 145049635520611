import React from "react";

//Action
import { actActiveTabsMedicalRecord } from "@actions";

//redux
import { useDispatch } from "react-redux";

function ButtonCloseERM(props) {
    const { handleExamMenu, isCurrentTask } = props && props;
    const dispatch = useDispatch(); 

    return (
        <span
            className="medRedcord-tabs__heading--close icon-close"
            onClick={() => {
                handleExamMenu(isCurrentTask, "closeTask");

                //Set reducer null
                dispatch(actActiveTabsMedicalRecord(null));
            }}
            current-task={isCurrentTask}
        ></span>
    );
}

export default ButtonCloseERM;