import React, { useEffect, useState } from "react";
import { TabContent } from "reactstrap";

//components
import {
    TabList,
    History,
    VitalSignTable,
    Measurements,
    IOBalance,
    PriorDocumentsComponent,
    OrderList // Copy Component moi - can xoa
} from "../TabGreen";

//lodash
import _ from "lodash";

//Result
import { ListTabsResult } from "../Results";

//call Action
import { actFetchOrderRequest, atcClickResult } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//components PatientSummaryMain
import { PatientSummaryMain } from "../PatientSummary";

//Chat
import { ChatRecordsTab } from "../ChatRecords";

// Check show tabs
function checkIsShow(list, typeToCheck) {
    return list.some(item => item.Task === typeToCheck);
}

function MedRecordTabContent(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { caseData, isCurrentTask } = props && props;
    const listCase = caseData?.data?.Case;
    const propsVitalSigns = listCase?.ClinicalStatus?.VitalSigns;
    const dataClinicalStatus = listCase?.ClinicalStatus;
    const measurementsData = dataClinicalStatus?.Measurements;
    const dataInterface = listCase?.Interface;
    const IOBalanceData = dataClinicalStatus?.IOBalance;
    const Flow = listCase?.Tasks?.Flow;
    const Timeline = listCase?.Timeline;
    const TimePointNum = Timeline?.TimePointNum;
    const PriorDocuments = listCase?.PriorDocuments;
    const dataVitalSigns = propsVitalSigns && Object.entries(propsVitalSigns);
    const typeMeasurements = dataInterface?.Elements && dataInterface?.Elements.Measurements;
    const ShowPatientSummaryPage = caseData?.data?.Case?.PatientSummary?.ShowPatientSummaryPage;
    const InteractiveItems = dataInterface?.InteractiveItems;

    //get data store
    const ordersData = useSelector((state) => state.orders || {});

    //state
    const [activeTab, setActiveTab] = useState(isCurrentTask);
    const [arrDotResult, setStatusDotResult] = useState({
        Labs: false,
        Studies: false
    });
    const [checkDataDiff, setDataDiff] = useState([]);

    // Request Api orders for tabs
    useEffect(() => {
        dispatch(actFetchOrderRequest(id));
    }, [dispatch, id]);

    //Handle tab
    const toggle = (type) => {
        setActiveTab(type);

        //Neu Click Tab Result Thi Mac Dinh Cho Labs Da xem
        type === 'Results' && handleClickHideDot('Labs');
    };

    //Kiem tra xem co luuu o local khong
    useEffect(() => {
        //Get local stotage Studies, Labs
        const ClinicalStatusLocalGet = localStorage.getItem("ClinicalStatusLocal");
        const jsonClinicalStatusLocalGet = JSON.parse(ClinicalStatusLocalGet);
        jsonClinicalStatusLocalGet && setDataDiff(jsonClinicalStatusLocalGet);
    }, [])

    //Hidden When click
    const handleClickHideDot = (type) => {
        const params = {
            "Task": type === "Labs" ? "view_lab" : "view_study"
        }
        dispatch(atcClickResult(params, id));
    }

    useEffect(() => {
        //So sanh du lieu cu va du lieu moi dua tren timepoint
        const compareDataTimepoint = (data, type) => {
            // Compare LabResults
            const filterDataBytimePointCurrent = _.filter(data, function (o) { return o.TimePointNum === TimePointNum; });
            const filterDataBytimePointOld = _.filter(data, function (o) { return o.TimePointNum === (TimePointNum - 1); });
            const objectCurrent = filterDataBytimePointCurrent && filterDataBytimePointCurrent[0];
            const objectOld = filterDataBytimePointOld && filterDataBytimePointOld[0];
            const resultsDataCurrent = objectCurrent ? (objectCurrent[type]?.results || objectCurrent[type]) : []; // kiem tra neu undefine
            const resultsDataOld = objectOld ? (objectOld[type]?.results || objectOld[type]) : []; // kiem tra neu undefine
            const compareResults = resultsDataCurrent && resultsDataOld && JSON.stringify(resultsDataCurrent) !== JSON.stringify(resultsDataOld);
            return compareResults;
        }

        //hien thi gia tri khi moi load vao
        const renderDot = (type, value) => {
            if (compareDataTimepoint(checkDataDiff, value) && !arrDotResult[type]) {
                setStatusDotResult((prevState) => ({ ...prevState, [type]: true }));
            }
        }
        renderDot('Labs', 'LabResults');
        renderDot('Studies', 'StudyResults');
    }, [TimePointNum, arrDotResult, checkDataDiff])


    //Tabs
    const listNavLink = [
        {
            Icon: "icon-emr-2",
            Title: "Patient Summary",
            Tooltip: "Patient Summary",
            Type: "PatientErm",
            Component: (
                <PatientSummaryMain {...props} />
            ),
            isShow: ShowPatientSummaryPage
        },
        {
            Icon: "fa fa-pencil-square-o",
            Title: "Narrative",
            Tooltip: "Narrative",
            Type: "NarrativeHistory",
            Component: (
                <History
                    {...props}
                />
            ),
            isShow: true
        },
        {
            Icon: "fa fa-heart-o",
            Title: "Vital Signs",
            Tooltip: "Vital Signs",
            Type: "VitalSigns",
            Component: (
                <React.Fragment>
                    <VitalSignTable data={dataVitalSigns || []} />
                    {typeMeasurements?.length > 0 && (
                        <Measurements
                            {...props}
                            type={typeMeasurements}
                            data={measurementsData || {}}
                        />
                    )}
                </React.Fragment>
            ),
            isShow: parseInt(id) !== 754 //Hide “Vital Signs” in EMR for case 754 only
        },
        {
            Icon: "fa fa-tint",
            Title: "Intake / Output",
            Tooltip: "Intake / Output",
            Type: "IntakeOutput",
            Component: (
                <IOBalance
                    active={activeTab}
                    data={(IOBalanceData && IOBalanceData) || {}}
                />
            ),
            isShow: Flow?.includes('io')
        },
        {
            Icon: "icon-results-1",
            Title: "Results",
            Tooltip: "Results",
            Type: "Results",
            Component: (
                <ListTabsResult
                    {...props}
                    TimePointNum={TimePointNum}
                    handleClickHideDot={handleClickHideDot}
                    arrDotResult={arrDotResult}
                />
            ),
            isShow: ![775, 754].includes(Number(id))
        },
        {
            Icon: "icon-add-note",
            Title: "Orders",
            Tooltip: "Orders",
            Type: "labs",
            Component: (
                <OrderList
                    {...props}
                    data={ordersData}
                    active={activeTab}
                />
            ),
            isShow: checkIsShow(InteractiveItems, "labs")
        },
        {
            Icon: "fa fa-folder-open",
            Title: "Docs",
            Tooltip: "Prior Documents & Records",
            Type: "Prior_Documents",
            Component: (
                <PriorDocumentsComponent
                    {...props}
                    active={activeTab}
                    TimePointNum={TimePointNum}
                />
            ),
            isShow: PriorDocuments?.length > 0 || false
        },
        {
            Icon: "fa fa-commenting-o",
            Title: "Chat Records",
            Tooltip: "Chat Records",
            Type: "Chat_Records",
            Component: <ChatRecordsTab {...props} />,
            isShow: true
        }
    ];

    //Filter component show
    const listNavShow = _.filter(listNavLink, function (o) { return o.isShow; });
    const checkActiveTabs = () => {
        let activeTab = null;
    
        // Ưu tiên tìm theo isCurrentTask trước
        if (isCurrentTask) {
            const currentTaskTab = listNavShow.find(type => type.Type === isCurrentTask);
            if (currentTaskTab) {
                activeTab = currentTaskTab.Type;
            }
        }
    
        // Nếu không tìm thấy theo isCurrentTask, lấy object đầu tiên
        if (!activeTab && listNavShow.length > 0) {
            activeTab = listNavShow[0].Type;
        }
    
        return activeTab;
    }

    //Active Tabs - Load lan dau
    useEffect(() => {
        // Nếu không có giá trị isCurrentTask thì xác định tab hoạt động mới
        const newActiveTab = checkActiveTabs();
        if (newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="medRedcord-tabs-detail">
            <TabList
                {...props}
                toggle={(e) => toggle(e)}
                activeTab={activeTab}
                data={listNavShow}
                TimePointNum={TimePointNum}
                arrDotResult={arrDotResult}
            />
            <TabContent activeTab={activeTab} className="tabs-med-content">
                {listNavShow?.map((item, index) =>
                    <div
                        key={index}
                        className={`${activeTab === item?.Type ? "d-block" : "d-none"}`}
                    >
                        {item?.isShow && item.Component}
                    </div>
                )}
            </TabContent>
        </div>
    );
}

export default MedRecordTabContent;