  import React from 'react';

  //react-redux
  import { useSelector } from "react-redux";

  //components
  import {
    InputFreeText,
    QuestionSelectMany,
    QuestionSelectOne,
    ButtonNextQuestion,
    MessageBoxEndChat,
  } from '@components/learner/StandardScreen/Chat/Preceptor';

  const QuestionContainer = (props) => {
    const {
      divRef,
      nextQuestion,
      TaskToResume,
      SubType,
      Type,
      optionsData,
      handleSelectMany,
      handleSelectOne,
      handleSubmitQuestion,
      valueOptions,
      isMessageLoader,
      handleNextQuestion
    } = props;

    // Get the question data and loading state from the Redux store
    const questionData = useSelector((state) => state.question || {});
    const QuestionResponse = questionData?.data?.Question;
    const isQuestionLoading = questionData?.isLoading;

    return (
      <div ref={divRef}>
        {!isQuestionLoading && !nextQuestion && (
          <div className={`questionBottom ${nextQuestion ? "mx-auto" : "bg-white"}`}>
            {!TaskToResume && (
              <React.Fragment>
                {SubType === "select_many" && Type === "multiple_choice" && (
                  <QuestionSelectMany
                    {...props}
                    handleSelectMany={handleSelectMany}
                    QuestionResponse={QuestionResponse}
                    AnswerChoices={optionsData?.SelectMany}
                  />
                )}
                {SubType === "select_one" && Type === "multiple_choice" && (
                  <QuestionSelectOne
                    {...props}
                    handleSelectOne={handleSelectOne}
                    QuestionResponse={QuestionResponse}
                    AnswerChoices={optionsData?.SelectOne}
                  />
                )}
                {QuestionResponse &&
                  <InputFreeText
                    {...props}
                    handleSubmitChoices={handleSubmitQuestion}
                    handleSubmitFreeText={handleSubmitQuestion}
                    isLoading={questionData?.isLoading?.submitQuestion}
                    valueOptions={valueOptions}
                    isMessageLoader={isMessageLoader}
                  />
                }
              </React.Fragment>
            )}
          </div>
        )}
        {TaskToResume &&
          <MessageBoxEndChat
            {...props}
            TaskToResume={TaskToResume}
          />
        }
        {nextQuestion && !TaskToResume &&
          <ButtonNextQuestion
            {...props}
            handleNextQuestion={handleNextQuestion}
            isLoading={questionData?.isLoading?.nextQuestion}
          />
        }
      </div>
    );
  };

  export default QuestionContainer;