import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Input, Label, FormFeedback, Row } from "reactstrap";

//Icon
import { Iconsax } from "@components-common";

//toast
import { toast } from 'react-toastify';

//untils
import { apiCaller } from "@utils";

//components
import { FormHeader, FileUpload, SubmitButtons, ButtonAddLevel } from "../AddUpdateFood";

//Language
import { useLanguage } from '@context/LanguageContext';

// Danh sách giá trị cần bị vô hiệu hóa
const disabledValues = ["0", "1", "2"];

// Dữ liệu cho modify
const modifyOptions = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7EC", label: "7EC" }
];

// Danh sách giá trị mặc định
const defaultLevelOptions = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "7EC", label: "7EC" },
    { value: "7RG", label: "7RG" }
];

const AddUpdateFoodForm = (props) => {
    let history = useHistory();
    const { language } = useLanguage();

    //Generate Food AI
    const storedData = localStorage.getItem('generatedFoodData');
    let foodAIData = JSON.parse(storedData);

    //props
    const { title, foodItem } = props && props;

    //react-hook-form - params
    const { register, handleSubmit, formState: { errors }, setValue, trigger, watch } = useForm({
        defaultValues: {
            food_name: foodItem?.food_name || foodAIData?.food_name || "",
            default_level: foodItem?.default_level || foodAIData?.default_level || "",
            default_level_note: foodItem?.default_level_note || foodAIData?.default_level_note || ""
        },
    });

    //state
    const [modificationData, setModificationData] = useState({
        levels: [{ modify_to_level: '', modify: '' }]
    });
    const [imageFile, setImageFile] = useState(null);
    const [isManuallyUpload, setManuallyUpload] = useState(false);
    const [isLoading, setLoading] = useState({
        submitForm: false
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [loadingGenerateImage, setLoadingGenerateImage] = useState(false);

    // Set default form values based on foodItem data
    useEffect(() => {
        const data = foodItem || foodAIData;
        if (data) {
            const levels = data.modifications ? Object.keys(data.modifications).map(key => ({
                modify_to_level: key.replace("modify_", ""),
                modify: data.modifications[key]
            })) : [];

            setModificationData({ levels });

            // Set default values
            setValue("food_name", data.food_name || "");
            setValue("default_level", data.default_level || "");
            setValue("default_level_note", data.default_level_note || "");
            foodAIData?.image_url && setImagePreview(foodAIData?.image_url );

            // Set values for modification levels
            levels.forEach((level, index) => {
                setValue(`modify_to_level_${index}`, level.modify_to_level || "", { shouldDirty: false });
                setValue(`modify_${index}`, level.modify || "", { shouldDirty: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //foodAIData.food_name - Hạn chế rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [foodItem, foodAIData?.food_name]);

    // Validate file
    const validateFileSize = (file) => {
        const fileSizeInMB = file.size / (1024 * 1024); // Chuyển đổi kích thước thành MB
        return fileSizeInMB <= 10; // Kiểm tra nếu kích thước nhỏ hơn hoặc bằng 10MB
    };

    // useEffect để gọi lại renderModificationLevelGroups khi modificationData thay đổi
    useEffect(() => {
        renderModificationLevelGroups(modificationData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modificationData]);

    // Handle Change Image
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const fileSizeInMB = file.size / (1024 * 1024); // Kích thước tệp tin tính bằng MB
        if (fileSizeInMB > 10) {
            toast.error("File size exceeds the limit of 10MB.", {
                position: toast.POSITION.TOP_RIGHT
            });
            e.target.value = null; // Xóa lựa chọn tệp tin để cho phép người dùng chọn lại
            return;
        }
        setManuallyUpload(true);
        setImageFile(file);
    
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };    

    //Submit API
    const onSubmit = (data) => {
        setLoading(prevState => ({ ...prevState, submitForm: true }));

        const isAllLevelsSelected = modificationData.levels.every(level => level.modify_to_level !== '');

        if (!isAllLevelsSelected) {
            toast.error("Please select all Modify to Levels.", {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoading(prevState => ({ ...prevState, submitForm: false }));
            return;
        }

        // Kiểm tra kích thước tệp tin
        if (imageFile && !validateFileSize(imageFile)) {
            toast.warning("File size exceeds the limit of 10MB.", {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoading(prevState => ({ ...prevState, submitForm: false }));
            return;
        }

        // Tiếp tục với việc gửi dữ liệu nếu tất cả điều kiện được đáp ứng
        const formdata = new FormData();
        formdata.append("food_name", data?.food_name);
        imageFile && formdata.append("image", imageFile || null);
        formdata.append("default_level", data?.default_level);
        formdata.append("default_level_note", data?.default_level_note);
        formdata.append("lang", language.value);

        // Upload Url Image By AI
        !isManuallyUpload && formdata.append("image_url", imagePreview);

        modificationData.levels.forEach(({ modify_to_level, modify }) => {
            formdata.append(`modify_${modify_to_level}`, modify);
        });

        const actionType = foodItem ? "edit_item" : "add_new_item";
        formdata.append("action_type", actionType);

        if (foodItem && foodItem.food_id) {
            formdata.append("food_id", foodItem.food_id);
        }
        //Truyền true hàm apiCaller để sử dụng api makansafe
        apiCaller(`/api/iddsi/functional/`, "POST", formdata, true).then(res => {
            if (res?.status === 200) {
                setLoading(prevState => ({ ...prevState, submitForm: false }));

                // Clear localStorage after successful submission
                localStorage.removeItem('generatedFoodData');

                handleBackHome();
            } else {
                console.log("error");
                setLoading(prevState => ({ ...prevState, submitForm: false }));
            }
        });
    };

    // Function to add a new modification level group
    const addModificationLevel = () => {
        if (modificationData?.levels?.length < 7) {
            const newModificationData = {
                ...modificationData,
                levels: [...modificationData.levels, { modify_to_level: '', modify: '' }]
            };

            setModificationData(newModificationData);
        } else {
            toast.warning("You can't add more than 7 modification levels.", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    // Handle Remove Item Of Modifications
    const removeModificationLevel = (indexToRemove) => {
        const newLevels = modificationData.levels.filter((_, index) => index !== indexToRemove);

        // Cập nhật state với dữ liệu mới
        setModificationData(prevState => ({
            ...prevState,
            levels: newLevels
        }));
    };


    // Handle Click Back
    const handleBackHome = () => {
        history.push("/");
    }

    const renderModificationLevelGroups = () => {
        const handleLevelChange = (index, value) => {
            setModificationData(prevState => ({
                ...prevState,
                levels: prevState.levels.map((level, idx) => idx === index ? { ...level, modify_to_level: value } : level)
            }));
            setValue(`modify_to_level_${index}`, value);
            trigger(`modify_to_level_${index}`);
        };

        const handleModificationChange = (index, value) => {
            setModificationData(prevState => ({
                ...prevState,
                levels: prevState.levels.map((level, idx) => idx === index ? { ...level, modify: value } : level)
            }));
            setValue(`modify_${index}`, value);
            trigger(`modify_${index}`);
        };

        return modificationData?.levels.map((level, index) => {
            return (
                <div className="modification-group card-area bg-grey mb-4" key={index}>
                    <Row>
                        <Col lg={4} sm={4}>
                            <FormGroup className="mt-2">
                                <Label className="ms-label" htmlFor={`modify_to_level_${index}`}>
                                    Modify to Level <span className="text-danger">*</span>
                                </Label>
                                <select
                                    className={`form-control ${errors[`modify_to_level_${index}`]?.type === "required" ? 'is-invalid' : ''}`}
                                    name={`modify_to_level_${index}`}
                                    value={level.modify_to_level}
                                    {...register(`modify_to_level_${index}`, { required: true })}
                                    onChange={(e) => handleLevelChange(index, e.target.value)}
                                    invalid={errors[`modify_to_level_${index}`]?.type === "required"}
                                    autoComplete="off"
                                >
                                    <option value="">Select a level...</option>
                                    {modifyOptions.map((option) => {
                                        const isDisabled = modificationData?.levels.some(l => {
                                            return (l.modify_to_level === option.value && l !== level) || disabledValues.includes(option.value);
                                        });
                                        return (
                                            <option key={option.value} value={option.value} disabled={isDisabled}>
                                                {option.label}
                                            </option>
                                        );
                                    })}
                                </select>
                                {errors[`modify_to_level_${index}`]?.type === "required" && (
                                    <FormFeedback className="d-block">This field is required.</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg={8}>
                            <FormGroup className="mt-2">
                                <Label className="ms-label" htmlFor={`modify_${index}`}>
                                    Modification <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="textarea"
                                    name={`modify_${index}`}
                                    value={level.modify}
                                    {...register(`modify_${index}`, { required: true })}
                                    onChange={(e) => handleModificationChange(index, e.target.value)}
                                    invalid={errors[`modify_${index}`]?.type === "required"}
                                    rows={5}
                                    autoComplete="off"
                                />
                                {errors[`modify_${index}`]?.type === "required" && (
                                    <FormFeedback>This field is required.</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    {modificationData?.levels?.length > 1 && (
                        <div className="del-modification-group" onClick={() => removeModificationLevel(index)}>
                            <Iconsax iconName="x" fill="#D92D20" />
                        </div>
                    )}
                </div>
            );
        });
    };

    // Generate Image AI
    const handleRegenerateImage = async (e) => {
        e.preventDefault();
        if (!foodAIData?.food_name) return;
        const params = {
            action_type: "flux_drawing",
            description: foodAIData.food_name,
            is_pro: false,
            aspect_ratio: "1:1",
        };
    
        setLoadingGenerateImage(true);
        setImageFile(null);
        setManuallyUpload(false);
        try {
            const res = await apiCaller(`/api/binh/openai/`, "POST", params, true);
            if (res?.status === 200) {
                setImagePreview(res.data);
            } 
        } catch (error) {
            console.error("Error generating image:", error);
        } finally {
            setLoadingGenerateImage(false);
        }
    };

    console.log(loadingGenerateImage);
    console.log(isLoading);
    return (
        <React.Fragment>
            <FormHeader title={title} />
            <div className="form-add-food">
                <Form>
                    <Row>
                        <Col lg={12}>
                            <div className="border-bottom py-2">
                                <FormGroup>
                                    <Label className="ms-label" for="food_name">
                                        Food Name <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="food_name"
                                        {...register("food_name", { required: true })}
                                        defaultValue={watch("food_name")}
                                        onChange={(e) => {
                                            setValue("food_name", e.target.value, { shouldValidate: true });
                                        }}
                                        invalid={errors.food_name?.type === "required"}
                                        placeholder=""
                                        autoComplete="off"
                                    />
                                    <FormFeedback invalid={String(errors.food_name?.type === "required")}>
                                        This field is required.
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <FormGroup className="mt-2">
                                <Label className="ms-label" for="default_level">
                                    Default Level <span className="text-danger">*</span>
                                </Label>
                                <select
                                    type="select"
                                    name="default_level"
                                    className={`form-control ${errors.default_level?.type === "required" ? 'is-invalid' : ''}`}
                                    {...register("default_level", { required: true })}
                                    defaultValue={watch("default_level")}
                                    onChange={(e) => {
                                        setValue("default_level", e.target.value, { shouldValidate: true });
                                    }}
                                >
                                    <option value="">Select default level...</option>
                                    {defaultLevelOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {errors.default_level?.type === "required" && (
                                    <FormFeedback className="d-block">This field is required.</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg={8}>
                            <FormGroup className="mt-2">
                                <Label className="ms-label" for="default_level_note">
                                    Default Level Description <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="textarea"
                                    name="default_level_note"
                                    {...register("default_level_note", { required: true })}
                                    defaultValue={watch("default_level_note")}
                                    onChange={(e) => {
                                        setValue("default_level_note", e.target.value, { shouldValidate: true });
                                    }}
                                    invalid={errors.default_level_note?.type === "required"}
                                    rows={5}
                                    autoComplete="off"
                                />
                                <FormFeedback invalid={String(errors.default_level_note?.type === "required")}>
                                    This field is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <h4 className="border-top py-3">Modification</h4>
                        </Col>
                    </Row>
                    {renderModificationLevelGroups(modificationData)}
                    <Row>
                        <ButtonAddLevel
                            {...props}
                            addModificationLevel={addModificationLevel}
                        />
                        <FileUpload 
                            {...props}
                            handleImageChange={handleImageChange}
                            imagePreview={imagePreview}
                            foodItem={foodItem}
                            handleRegenerateImage={handleRegenerateImage}
                            foodAIData={foodAIData}
                            isManuallyUpload={isManuallyUpload}
                            loadingGenerateImage={loadingGenerateImage}
                            isPage={foodItem ? "edit" : foodAIData ? "add-by-ai" : "add"}
                        />
                        <div className="my-4 py-4 border-top w-100">
                            <SubmitButtons
                                {...props}
                                isLoading={isLoading?.submitForm}
                                handleBackHome={handleBackHome}
                                handleSubmit={handleSubmit(onSubmit)}
                                disabledButton={loadingGenerateImage}
                            />
                        </div>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    );
};

export default AddUpdateFoodForm;