import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Icon } from '@components/common'
import FileCase from '@images/teachV2/file-case.svg'
import { useSelector, useDispatch } from "react-redux";
import { DataEmptyCase } from "@components-common";
import { getReferenceMedia } from "@actions";
import { wizardConstants } from "@constants";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function SelectListCommonImage(props) {
  const { title, subtitle, setValue, dataForm, watch } = props;
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const { data: documents } = useSelector((state) => state.getReferenceMedia) || {};
  const [selectedBooks, setSelectedBooks] = useState(watch(dataForm?.field) || []);

  useEffect(() => {
    dispatch(getReferenceMedia())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleActionBack = () => {
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_MEDIA_SUCCESS, payload: null });
    setValue(dataForm?.field, null)
  }

  const columns = [
    {
      dataField: "title",
      text: "",
      headerClasses: "align-middlefont-weight-500",
      classes: "align-middle",
      sort: true,
      editable: false,
      formatter: (value, row) => {
        return (
          <div className={`d-flex justify-content-between item-book`}>
            <div className='d-flex'>
              <Icon src={FileCase} />
              <p className="ml-2">{value}</p>
            </div>
          </div>
        );
      },
    },
  ]

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    hideSelectColumn: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedBooks([row]);
        setValue(dataForm?.field, [row])
      } else {
        setSelectedBooks(selectedBooks.filter((book) => book.id !== row.id));
        setValue(dataForm?.field, selectedBooks.filter((book) => book.id !== row.id))
      }
    },
    selected: selectedBooks.map(book => book.id)
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="id"
          data={documents?.Combined || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                  paginationProps.page = 1
                  toolkitprops.searchProps.onSearch(e)
                }} />
              </div>
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive list-books"
                data={documents?.Combined || []}
                columns={columns}
                noDataIndication={() => (
                  <DataEmptyCase
                    title="No Results Found"
                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                  />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                selectRow={selectRow}
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="pagination-custom-case d-flex justify-content-end ml-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: documents?.Combined.length || [],
  };

  return (
    <WrapCommon>
      <div className="form-area">
        <div className="form-area__content">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        <div className="form-area__select">
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        </div>
      </div> 
      <div className='btn-form'>
        <ButtonSubmitCommon {...props} handleBack={handleActionBack}/>
      </div>     
    </WrapCommon>
  )
}
