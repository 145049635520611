import React from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import { TitleNoteCommon } from '../../home/generate';
import { Controller } from 'react-hook-form';
import Select from "react-select";
import { listTemplate, listChatGpt, listPopular} from '@utils';
import { Icon } from '@components/common'
import popular from '@images/teachV2/popular.svg'

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function InputDropdownCommon(props) {
  const { title, subtitle, watch, setValue, control } = props;

  const Option = ({ innerProps, label, data, value }) => {
    return (
      <div
        {...innerProps}
        className='option-detail'
        style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
        <span className="react-select-item">{label}</span>
        {listPopular?.includes(label) && <div>
          <Icon src={popular} />
        </div>}
      </div>
    );
  };

  return (
    <WrapCommon>
      <div className="form-area">
        <div className="form-area__content">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        <div className="form-area__dropdown">
          <div className='wrap-option'>
            <TitleNoteCommon 
              dataFor={'template'} 
              title={'Template'} 
              content=""
            />
            <Controller
              control={control}
              name="template_id"
              defaultValue={listTemplate[1]?.value}
              render={({ field: { onChange } }) => (
                <>
                  <Select
                    className="react-select-common w-100"
                    placeholder="Select Modal"
                    value={listTemplate?.find((c) => c.value === watch("template_id"))}
                    options={listTemplate}
                    onChange={val => {
                      setValue("template_id", val.value)
                      onChange(val.value)
                    }}
                    classNamePrefix="react-select-custom"
                    components={{
                      IndicatorSeparator: () => null,
                      Option: Option
                    }}
                  />
                </>
              )}
            />
          </div>
          <div className='wrap-option'>
            <TitleNoteCommon 
              dataFor={'model'} 
              title={'Model'} 
              content=""
            />
            <Controller
              control={control}
              name="gpt_model"
              defaultValue={"gpt-4o"}
              render={({ field: { onChange } }) => (
                <>
                  <Select
                    className="react-select-common w-100"
                    placeholder="Select Template"
                    value={listChatGpt?.find((c) => c.value === watch("gpt_model"))}
                    options={listChatGpt}
                    onChange={val => {
                      setValue("template_id", val.value)
                      onChange(val.value)
                    }}
                    classNamePrefix="react-select-custom"
                    components={{
                      IndicatorSeparator: () => null,
                      Option: Option
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>
      </div> 
      <div className='btn-form'>
        <ButtonSubmitCommon {...props} dataPass={watch("content")}/>
      </div>     
    </WrapCommon>
  )
}
