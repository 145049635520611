import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import { Icon } from '@components/common'
import upload from '@images/teachV2/upload-cloud.svg'
import { DataFileImported } from '../../home/generate'
import { listFileAccept } from '@utils'
import { useDispatch } from "react-redux";
import { wizardConstants } from "@constants";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function UploadCommon(props) {
  const { title, subtitle, setValue, dataForm, watch } = props;
  const dispatch = useDispatch();
  const hiddenFileInputText = useRef(null);
  const [dataFileImported, setDataFileImported] = useState(watch(dataForm?.field) || null);

  const handleDrag = function (e) {
    e.preventDefault();
  }

  const handleChange = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    setDataFileImported(type === "change" ? event.target.files[0] : event.dataTransfer.files[0])
    setValue(dataForm?.field, type === "change" ? event.target.files[0] : event.dataTransfer.files[0])
  };

  const handleClick = event => {
    hiddenFileInputText.current.click();
  };

  const handleTryAgain = async () => {
    await setDataFileImported(null)
    await hiddenFileInputText.current.click();
  }

  const handleActionBack = () => {
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_REFERENCE_SUCCESS, payload: null });
    setValue(dataForm?.field, null)
  }

  return (
    <WrapCommon>
      <div className="form-area">
        <div className="form-area__content">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        <div className="form-area__upload">
          {!dataFileImported ? (
            <div className={'form-area__upload-input'} onDragOver={handleDrag} onDrop={(e) => handleChange(e, "drop")} onClick={handleClick}>
              <input
                type="file"
                onChange={(e) => handleChange(e, "change")}
                ref={hiddenFileInputText}
                name="text"
                style={{ display: "none" }}
              />
              <div className='form-area__upload-icon'>
                <Icon src={upload} />
              </div>
              <div className='form-area__upload-text'>
                <p className="list__input-item--note"><span>Click to upload</span> or drag and drop your file here</p>
                <p className="list__input-item--note">PDF, PPT, Word, TXT. Max: 10 MB.</p>
              </div>
            </div>
          ) : (
            <DataFileImported
              {...props}
              listFileAccept={listFileAccept}
              dataFileImported={dataFileImported}
              handleTryAgain={handleTryAgain}
              setDataFileImported={setDataFileImported}
            />
          )}
        </div>
      </div> 
      <div className='btn-form'>
        <ButtonSubmitCommon {...props} handleBack={handleActionBack} dataPass={!listFileAccept?.includes(dataFileImported?.type) || dataFileImported?.size > 10000000}/>
      </div>     
    </WrapCommon>
  )
}
