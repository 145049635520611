import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Common components
import { MenuTaskColumn } from "@components/learner/StandardScreen/CaseTask";
import { ProgressBarTimePoint } from "@components/learner/StandardScreen";
import { LearnMainLayout } from "@components/learner";
import { Toasts } from '@components/common/Toasts';
import { toast } from 'react-toastify';
import LoaderPage from "@utils/LoaderPage";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

// Context
import { useLockScrollBody } from '@context/LockScrollBodyProvider';

// Presentation components
import {
  PNOrderSection,
  PatientImageBackground,
  // LearnMobileContainer
} from "./StandardScreen";
import { QuestionFullFixed } from "./StandardScreen/InteractAssistant/AssistantQuestion";
import { PatientErmContainer } from "./StandardScreen/ErmView";
import { VitalsignsOverlay } from "./StandardScreen/VitalSigns";
import { AssistantAvatarSmall, InteractAssistantBegin } from "./StandardScreen/InteractAssistant/AssistantQuestion";

//Mobile - MOdal
import { NarativePopup } from "@components/learner/StandardScreen/LearnerMobile/Narative";
import { ChatFeedBackPopup } from "@components/learner/StandardScreen/LearnerMobile";
import { ExamPopupMobile } from "@components/learner/StandardScreen/LearnerMobile/ExamTask";

// Layouts
import {
  LearnerGridRight,
  LearnerGridParent,
  LearnerGridLeftParent
} from "@components/learner/StandardScreen/GridLayout";

// Advance features
import { ActionsMain } from "@components/learner/StandardScreen/Actions";
import {
  ModalCompletedCase,
  CongratulationsTimePoint,
  ThankYouFeedback,
  InteractAdvanceCompleted
} from "@components/learner/StandardScreen/Advance";
import { ModalChatWrapUp } from "@components/learner/StandardScreen/Advance/ChatWrapUp";
import { UCREndCase } from "@components/learner/StandardScreen/Advance/ucr";
import { DefaultEndCase } from "@components/learner/StandardScreen/Advance/default";

// Feedback
import { ModalUserFeedback } from "@components/learner/StandardScreen/Feedback";

// Chat components
import { ChatInterface } from "./StandardScreen/Chat";

// Medical record components
import { ModalHumanModel } from "./StandardScreen/HumanModal";
import {
  ModalMedicalRecord,
  // ModalPushCheckMyNote,
} from "./StandardScreen/MedicalRecord";

// Constants
import { questionConstants, caseConstants, advanceConstants } from "@constants";

// Actions
import {
  actFetchOrderRequest,
  actFetchPutCaseRequest,
  actFetchCaseActionsRequest,
  // actFetchCheckMissingTask,
  actActiveTabsMedicalRecord,
  atcAddRecordToChanel,
  loadRecordInstuctor,
  loadRecordPatient,
  LoadInterfaceTemplate,
  toggleChatFeedbackMobile,
  NumberFeedbackTrigger,
  toggleExamMobile,
  resetChatRecordForQuestion,
  LoadVirtualPreceptorStatus,
  // atcUpdateLearnerDemo,
} from "@actions";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Tour components
import { AssistantDocumentation, LearnerTop } from "./StandardScreen/InteractAssistant"; //StreamChatGPT

// Utilities
import { apiCaller } from "@utils";

// import { advanceConstants } from "@constants";
// import _ from "lodash";

function StandardScreenMain(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const getSizeWrap = useRef();
  let intervalIDRef = React.useRef(null);

  //props
  // const { settings } = props && props;
  // const showLearnerDemo = settings?.show_learner_demo;

  //context
  const { unlockBodyScroll, lockBodyScroll } = useLockScrollBody();

  // ----------------------------------------------------------------------
  // data
  // ----------------------------------------------------------------------
  const { caseData } = props && props; //isLoadingCase
  let dataResponse = caseData?.data;
  let dataInterface = dataResponse?.Case?.Interface;
  let dataClinicalStatus = dataResponse?.Case?.ClinicalStatus;
  let TasksList = dataResponse?.Case?.Tasks;
  let TourData = dataResponse?.Case?.TourData;
  // let TipMessages = dataResponse?.TipMessages;
  let Documentation = dataResponse?.Case?.Documentation;
  let DocumentationItems = Documentation?.DocumentationItems;
  let DocumentationItemsText = DocumentationItems && DocumentationItems[0];
  let CurrentTimePointId = dataResponse?.Case?.Timeline?.CurrentTimePointId;
  let InteractiveItems = dataInterface?.InteractiveItems;
  let hasChatTask = InteractiveItems?.some(item => item.Task === "chat");
  const messageDocumentation = 'We have run into some issues with load the feedback. Can you click on the <bold>Review My Note</bold> button and try again?';

  // Use screen size context
  const { isMobile } = useScreenSize();

  // ----------------------------------------------------------------------
  // Modal EMR, Chat Feedback, Exam - Mobile
  // ----------------------------------------------------------------------
  const isOpenChatFBMobile = useSelector((state) => state.chatFeedbackReducer.isOpen);
  const isOpenExamBMobile = useSelector((state) => state.examMobileReducer.isOpen);
  const isOpenEMRMobile = useSelector((state) => state.ERMModalMobileReducer.isOpen);


  // Reset Chat Record khi load page (Chạy action này trước khi gọi hàm add Record)
  useEffect(() => {
    dispatch(resetChatRecordForQuestion());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  //Show Modal khi click Review Chat Record
  const statusTabActiveMedicalRecord = useSelector((state) => state.statusTaskActiveTabMedicalRecord?.activeTab || null);

  //View
  let initialState = {
    exam: false, //task
    pn: false, //task
    actions: false, //task
    chat: false, //task
    labs: false, //task
    advance: false, //task
    // View
    documentation: false,
    question: false,
    welcome: true,
    PatientErm: false,
    PatientErmFixed: false
  };

  // Khi check task sẽ loại trừ các name này
  let lisTaskPrimary = [`exam`, `pn`, `actions`, `chat`, `labs`, `advance`];
  let isViewChatFullScreen = "chatFullFixed";
  let nameCommonNextTimePoint = "check-next-timepoint";

  //state
  const [isLoading, setLoading] = useState({
    CDIEditSuggestion: false,
    btnNextTimepoint: false,
    btnBeginQuestion: false
  });
  const [loadImage, setLoadImage] = useState(true);
  // const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [sizePatientPhoto, setSizePatientPhoto] = useState({});
  const [isTask, setIsTask] = useState("history");
  const [disableNextTimepoint, setDisableNextTimepoint] = useState(true);
  const [switchChat, setSwitchChat] = useState("voice");
  const [overRideEffect, setOverRideEffect] = useState(false);
  const [taskNexTour, taskNameNextTour] = useState('begin-tour');
  const [messageErrDocumentation, setMessageErrDocumentation] = useState("");
  const [shouldReopenFeedback, setShouldReopenFeedback] = useState(false);
  const [modal, setModal] = useState({
    HumanModel: false,
    AssistantDocumentation: false
  });
  const [isOpenAdvance, setIsOpenAdvance] = useState({
    nextTimepoint: false,
    compeletedTimepoint: false,
    userFeedback: false,
    infoCompeleted: false,
    ThankYou: false,
    weitzman: false,
    ucrTesting: false,
    defaultEndCase: false
  });
  // const [isFeedback, setFeedback] = useState({
  //   Content: "",
  //   Type: null
  // });
  const [stateType, setStateType] = useState(initialState);
  const [stateCDIEditSuggestion, setCDIEditSuggestion] = useState(null);
  const [stateStream, setDataStream] = useState(null);
  let logicShowChat = stateType?.chat || stateType?.question;

  const [activeMenuTask, setActiveMenuOfTask] = useState("history");

  //Chat WrapUp
  const [isShowChatWrapUp, setShowChatWrapUp] = useState(false);
  const [alertPendingQuestion, setAlertPendingQuestion] = useState(false);

  //chat feedback
  const [isChatFeedbackOpen, setIsChatFeedbackOpen] = useState({
    chatFeedbackClick: false,
    endChatFeedback: false
  });
  const [endChatFeedbackData, setEndChatFeedback] = useState(null);

  // ----------------------------------------------------------------------
  // question
  // ----------------------------------------------------------------------
  const questionData = useSelector((state) => state.question || {});
  let isQuestionNull = questionData?.data?.Question === null;
  const isQuestionLoading = questionData?.isLoading;

  //Load Virtual Preceptor Status
  const isTaskReset = useSelector((state) => state?.VirtualPreceptorStatus?.taskReset || null);

  //Xoa overide css do bong cho button sidebar khi question null
  useEffect(() => {
    if (isQuestionNull) {
      handleRemoveOverideEffectMenu();
    }
  }, [isQuestionNull])
  const [ChatMode, setChatMode] = useState(dataInterface?.ChatMode);
  // ----------------------------------------------------------------------
  // end question
  // ----------------------------------------------------------------------

  // Check Missing Task
  const checkCaseReducer = useSelector(state => state?.checkMissingTask?.data || {});
  // skip the end-time point confirmation if the "required_user_confirmation" flag in time point check is false
  let { required_user_confirmation } = checkCaseReducer;

  // Load Chat Record - Instructor Chanel
  // Load Chat Patient - Instructor Chanel
  useEffect(() => {
    const fetchData = (channel) => {
      const params = {
        "Action": "Load",
        "CaseId": parseInt(id),
        "TimePointId": parseInt(CurrentTimePointId),
        "Channel": channel
      };
      if (channel === "Patient") {
        dispatch(loadRecordPatient(params));
      } else if (channel === "Instructor") {
        dispatch(loadRecordInstuctor(params));
      }
    };

    const fetchCaseInterface = () => {
      dispatch(LoadInterfaceTemplate(id));
    };

    // Gọi cho cả "Patient" và "Instructor"
    fetchData("Patient");
    fetchData("Instructor");
    fetchCaseInterface();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  //Loader Stream
  let currentStage = 0;
  const [loadingText, setLoadingText] = useState('');
  const stages = [
    { text: 'Reading Note ...', duration: 1000 },
    { text: 'Analyzing ...', duration: 2000 },
    { text: 'Preparing Feedback ...', duration: 1000 },
  ];

  //Loader Text for Stream
  const loadNextStage = () => {
    setLoadingText(stages[currentStage].text);

    if (currentStage === stages.length - 1) {
      // Start loading the stream or perform any other action here
      return;
    }

    currentStage++;
    setTimeout(loadNextStage, stages[currentStage].duration);
  };

  // Load Task and Begin welcome
  // Update task - Load Virtual Preceptor Status
  useEffect(() => {
    if (isTaskReset?.task) {
      setIsTask(isTaskReset.task);  // Set task if it exists in isTaskReset
      //Call lại api xem có question cho task này không ?
      callApiCheckQuestionWithTask(id, isTaskReset?.task);
    } else {
      setIsTask("history");  // Fallback to "history" if isTaskReset?.task is not present
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskReset, id]);

  //Request Api Labs for Orders
  useEffect(() => {
    const fetchData = async () => {
      dispatch(actFetchOrderRequest(id));
    };
    isTask === "labs" && fetchData(); 
  }, [dispatch, id, isTask]);

  //get data store
  const actionsData = useSelector((state) => state.actionsWidthCaseId || {});
  const advanceData = useSelector((state) => state.advance || {});
  // const ChatDataOfFreeText = useSelector((state) => state.ChatRecordFreeText || {});

  // ----------------------------------------------------------------------
  // Visit Documentation text
  // ----------------------------------------------------------------------
  const [stateDocumentationText, setDocumentationText] = useState(DocumentationItemsText?.text || "");
  const [isShowVitals, setShowVitals] = useState(dataInterface?.ShowVitals);
  const [stateInterFace, setInterface] = useState(dataInterface || {});

  // Load Interface
  useEffect(() => {
    const dataResponse = caseData?.data;
    const dataInterface = dataResponse?.Case?.Interface;
    setInterface(dataInterface || {});
    setChatMode(dataInterface?.ChatMode);
  }, [caseData])

  //Load for Editor
  useEffect(() => {
    setDocumentationText(DocumentationItemsText?.text);
  }, [DocumentationItemsText])

  //Check lock body
  useEffect(() => {
    const shouldLockScroll = stateType?.question || stateType?.chat || stateType?.welcome;

    if (shouldLockScroll) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }

    return () => {
      unlockBodyScroll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateType]);

  const handleDocumentationText = (data) => {
    setDocumentationText(data);
  }

  // ----------------------------------------------------------------------
  // advance
  // ----------------------------------------------------------------------
  const advanceResponse = advanceData?.data;

  //toggle Modal
  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setOverRideEffect(!modal[type]);
  };

  // Handle Instruction
  const handleFetchInstruction = (id, task) => {
    apiCaller(`/api/learn/case/assistant/${id}/?task=${task}`, "GET", null).then((res) => {
      const data = res?.data;
      const dataAlert = data?.alert;
      const alertSeverity = dataAlert?.Severity;
      // const alertDuration = dataAlert?.ShowDuration * 1000;
      if (res?.status === 200) {
        // Neu Question la null thi moi hien thi toast
        if (data?.status === "instruction") {
          if (alertSeverity === "success") {
            toast.success(<Toasts data={dataAlert} />, {
              autoClose: false,
              toastId: 'instruction_success',
            });
          }
          if (alertSeverity === "warning") {
            toast.warning(<Toasts data={dataAlert} />, {
              autoClose: false,
              toastId: 'instruction_warning',
            });
          }
          if (alertSeverity === "danger") {
            toast.error(<Toasts data={dataAlert} />, {
              autoClose: false,
              toastId: 'instruction_error',
            });
          }
        }
      }
    });
  };

  //Open Modal Advance (Next Timepoint)
  const toggleModalTimepoint = (isOpen) => {
    setIsOpenAdvance(prevState => ({ ...prevState, nextTimepoint: isOpen }));
  }

  //1. Kiem tra xem con question khac khong ?
  const callApiCheckQuestionWithTask = (id, task, types = null) => {
    let typeWelcome = types?.includes("welcome"); // Lấy type từ mảng
    let typeAdvaneClick = types?.includes(nameCommonNextTimePoint);

    //Begin View
    typeWelcome && setLoading((prevState) => ({ ...prevState, btnBeginQuestion: true }));

    dispatch({ type: questionConstants.QUESTION_REQUEST, isLoading: true });
    apiCaller(`/api/learn/question/next/${id}/?task=${task}`, 'GET', null).then(res => {
      const data = res?.data;
      const status = res?.status;
      const QuestionResponse = data?.Question;
      const isQuestionNull = QuestionResponse === null;
      if (status === 200) {
        //Update Reducer
        dispatch({ type: questionConstants.QUESTION_SUCCESS, payload: data, isLoading: false });

        //1. - Tắt view GotIt
        if (typeWelcome) {
          //kiem tra xem question co null khong, neu co thi show toats
          // (truong hop nay chi dung cho task la history khi load page)
          isQuestionNull && task === "history" && showMessageForHistoryTask(data);
          setLoading((prevState) => ({ ...prevState, btnBeginQuestion: false }));
          handleToogleTaskView(types);
        }

        // 2. Neu Question Null thi kiem Tra Instruction co khong ?
        if (isQuestionNull) {
          // Loại trừ check task history vì đã nhấn GotIt hoặc người dùng nhấn vào instructor để trả lời.
          task !== "history" && handleFetchInstruction(id, task);

          if (typeAdvaneClick) {
            // ưu tiên mở modal WrapUpFeedback, khi người dùng đọc xong close mới tiếp tục
            if (hasChatTask && [775, 754, 906, 1431].includes(Number(id))) {
              setShowChatWrapUp(true);
            } else {
              //Tiếp tục thực hiện logic show modal advance
              //Check xem kết thúc case chưa ?
              checkViewForAdvance();
            }
          }

          // Khi nào question null mà click Exam mới hiển thị
          isMobile && task === 'exam' && dispatch(toggleExamMobile(true));
        }

        //3. - Kiểm tra xem có question không, nếu có thì hiển thị bubble suggest avatar.
        //   - Thực hiện push Question Response vào action (CHAT_RECORD_FOR_QUESTION).
        if (QuestionResponse) {
          // - Nếu có question thì bật view question (trừ trường hợp task là chat).
          handleToogleQuestionView(task);

          //Đẩy data lên API
          hanldeAddRecordToChanel(QuestionResponse);
          handlePushChatRecordWileHasQuestion(QuestionResponse);
        }
      } else {
        dispatch({ type: questionConstants.QUESTION_FAILURE, error: "error", isLoading: false });

        //Begin View
        typeWelcome && setLoading((prevState) => ({ ...prevState, btnBeginQuestion: false }));
      }
    });
  }

  // Save Session
  const handleSavesessionStorage = (value) => {
    sessionStorage.setItem("CurrentTask", value);
  }

  // Khi click task - Nếu có question thì mở view question ( Chỉ phần Chat không tự động mở)
  const handleToogleQuestionView = (currentTask) => {
    if (currentTask !== "chat") {
      // Đảm bảo view chat được tắt khi bật view question.
      setStateType((prevState) => {
        const newState = {};
        for (const key in prevState) {
          newState[key] = key === "question" ? true : false;
        }
        return newState;
      });
    }
  }

  // Show View
  const handleToogleTaskView = (task, types = null) => {
    // Update state based on task conditions
    setStateType((prevState) => {
      let newState = {};

      if (task === "chat" || types?.includes(isViewChatFullScreen)) {
        // If task is "chat" or the view is in full screen, ensure the question view is off
        newState = { ...prevState, question: false, [task]: !prevState[task] };
      } else if (lisTaskPrimary.includes(task) && !['labs', 'actions'].includes(task)) {
        // Toggle only the selected task in lisTaskPrimary
        newState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = key === task;
          return acc;
        }, {});
      } else if (task !== 'advance') {
        // Toggle the selected task, turning off all others
        newState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = key === task ? !prevState[key] : false;
          return acc;
        }, {});
      }

      return newState;
    });
  };

  //hanlde Api Check Case
  const handleCheckCase = async (id) => {
    try {
      dispatch({ type: caseConstants.CHECK_MISSING_TASK_REQUEST, isLoading: true });
      const res = await apiCaller(`/api/learn/case/check/${id}/`, 'GET', null);
      if (res?.status === 200) {
        let dataCurrent = res?.data;
        // Update reducer
        dispatch({
          type: caseConstants.CHECK_MISSING_TASK_SUCCESS,
          payload: dataCurrent,
        });
        return res;
      }
    } catch (error) {
      dispatch({
        type: caseConstants.CHECK_MISSING_TASK_FAILURE,
        error: "API request failed"
      });
      return null;
    }
  };

  // Hàm xử lý status FailedCheckpoint
  const handleToastFailedCheckpoint = (id, task, res) => {
    const data = res?.data;
    if (data?.status === "failed_checkpoint" && data?.Alert) {
      toast.success(<Toasts data={data?.Alert} />, {
        autoClose: data?.Alert?.ShowDuration * 1000,
        toastId: 'failed_checkpoint',
      });
    }
  }

  // Hàm xử lý status MissingTask
  const handToastleMissingTask = (id, task, res) => {
    //Chỉ xảy ra khi click advance
    const data = res?.data;
    const status = data?.status;
    const toastMessage = data?.Message;
    const valueMessage = { Message: toastMessage };
    if (task === "advance") {
      toast.warning(<Toasts data={valueMessage} />, {
        toastId: status,
        autoClose: false
      });
    }
  }

  // Hàm xử lý status PendingQuestion
  const handleToastPendingQuestion = (task) => {
    setAlertPendingQuestion(true);
    handleToogleQuestionView(task);
  }

  //Check view Advance
  const checkViewForAdvance = () => {
    const { next, status } = checkCaseReducer || {};
    const { EndCaseView } = caseResponse || {};

    // Thiết lập giá trị mặc định cho EndCaseView nếu nó là null
    const finalEndCaseView = EndCaseView ?? "defaultEndCase";

    setDisableNextTimepoint(false);

    if (status === "OK") {
      const isWeitzman = finalEndCaseView === "weitzman";
      const isUcrTesting = finalEndCaseView === "ucr-testing";

      const finalCaseView = isWeitzman ? "weitzman" : (isUcrTesting ? "ucrTesting" : "defaultEndCase");

      // Kiểm tra nếu finalCaseView là null, sử dụng "defaultEndCase" thay vì finalCaseView
      const caseViewToSet = finalCaseView === null ? "defaultEndCase" : finalCaseView;

      if (next === null) {
        setIsOpenAdvance((prevState) => ({ ...prevState, [caseViewToSet]: true }));
      } else {
        // Hiển thị modal next timepoint
        toggleModalTimepoint(true);
      }
    }
  };

  // Sử dụng các status cho các handle
  function handleStatus(id, task, res, types = null) {
    const data = res?.data;
    const status = data?.status;
    const requiredUserConfirmation = data?.required_user_confirmation;
    // let nextTimePoint = data?.next;
    switch (status) {
      case "failed_checkpoint":
        handleToastFailedCheckpoint(id, task, res);
        callApiCheckQuestionWithTask(id, task);
        break;
      case "missing_task":
        //Loại trừ khi mở view instructor chat full screen
        !types?.includes(isViewChatFullScreen) && handToastleMissingTask(id, task, res);
        break;
      case "OK":
        let typeAdvaneClick = types?.includes(nameCommonNextTimePoint);

        // Check Question Next
        typeAdvaneClick && callApiCheckQuestionWithTask(id, task, types);

        // Pending Question Next - Check if required_user_confirmation is false
        !isQuestionLoading && isQuestionNull && handleAutoRedirectNextTimePoint(requiredUserConfirmation);
        break;
      case "PendingQuestion":
        handleToastPendingQuestion(task);
        break;
      default:
        console.log("Not Status");
    }
  }

  // Auto redirect Next Time Point - Gọi sau khi check Question Next
  const handleAutoRedirectNextTimePoint = (requiredUserConfirmation) => {
    if (requiredUserConfirmation === false) {
      dispatch({ type: advanceConstants.AUTO_NEXT_TIMEPOINT_REQUEST, isLoading: true });
      apiCaller(`/api/learn/case/advance/`, 'POST', { "CaseId": id }).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          dispatch({ type: advanceConstants.AUTO_NEXT_TIMEPOINT_SUCCESS, payload: data, isLoading: false });
          window.location.reload();
        }
      })
        .catch(() => console.log("error"))
        .finally(() => dispatch({ type: advanceConstants.AUTO_NEXT_TIMEPOINT_FAILURE, error: "error", isLoading: false })); // Stop loader after the update completes
    }
  }

  // Check Logic Nextimepoint
  const continueCheckLogicNextTimepoint = async (id, task) => {
    setLoading(prevState => ({ ...prevState, btnNextTimepoint: true }));
    try {
      const res = await handleCheckCase(id);
      if (res?.data?.next === null) callApiCheckQuestionWithTask(id, task, nameCommonNextTimePoint);
      if (res?.status === 200) handleStatus(id, task, res, [nameCommonNextTimePoint]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(prevState => ({ ...prevState, btnNextTimepoint: false }));
    }
  };

  // Handle Click Button Next Timepoint
  async function handleClickBtnNextTimePoint(id, task) {
    //Setstate click task
    handleActiveTask(task);

    continueCheckLogicNextTimepoint(id, task);
  }

  //handle check case and question
  async function handleCheckCaseWithStatus(id, task, types) {
    const res = await handleCheckCase(id);
    if (res?.status === 200) {
      handleStatus(id, task, res, types);
    }
  }

  //Handle Active Task
  const handleActiveTask = (task) => {
    setIsTask(task);

    // Chỉ khi nào click vào Order của Case Tasks mới active.
    if (task === "labs") {
      dispatch(actActiveTabsMedicalRecord(task));
    }
  }

  //Handle click menu
  const handleExamMenu = (menuTask, type = null) => {
    let types = Array.isArray(type) ? type : [type]; //Chuyển type thành mảng nếu nó không phải mảng
    let valueOfTask = lisTaskPrimary?.indexOf(menuTask) !== -1;
    if (valueOfTask) {
      //Nếu là task chính thì active
      handleActiveTask(menuTask);

      //Gửi task hiện tại cho api và bắt buộc gọi Question Next sau khi api này Success
      dispatch(actFetchPutCaseRequest(id, menuTask)).then(response => {
        if(response?.status === 200) {
          const data = response?.data;
          if(data?.status === "Success") {
            callApiCheckQuestionWithTask(id, menuTask);
          }
        }
      }).catch(error => {
        console.error("Error:", error); // Handle the error here
      });

      // Check Case And Question Next
      handleCheckCaseWithStatus(id, menuTask, types);
    }

    // Xoa Instruction Cu
    // setInstruction(null);

    //Active Menu
    setActiveMenuOfTask(menuTask);

    //Ative View
    handleToogleTaskView(menuTask, types);

    //Save Task for session storage
    handleSavesessionStorage(menuTask);

    // Load Data for task
    switch (menuTask) {
      case "actions":
        dispatch(actFetchCaseActionsRequest(id));
        break;
      case "labs":
        dispatch(actFetchOrderRequest(id));
        break;
      case "chat":
        // Nếu task là chat thì call check instructions
        handleFetchInstruction(id, menuTask);
        break;
      case "exam":
        //Show Human Model
        stateInterFace?.UseHumanModel === true && setModal((prevState) => ({ ...prevState, HumanModel: true }));

        // Mở modal Exam - Mobile version
        isMobile && dispatch(toggleExamMobile(true));
        break;
      default:
        toggleModalTimepoint(false);
    }

    //Neu closeTask
    if (types?.includes("closeTask")) {
      // La Chat Thi mo lai Vital Signs
      ShowVitalOverLay(menuTask);

      //Neu Close Box thi remove hieu ung
      handleRemoveOverideEffectMenu();

      //Xoa Active Menu
      setActiveMenuOfTask(null);

      //Khi đóng thì check Question còn hay không để hiển thị tooltips
      handleCheckCase(id);
    }
  };

  // Show Vital Sign On Image Patient
  const ShowVitalOverLay = (task) => {
    const statusVital = stateInterFace?.ShowVitals;
    if (task === "chat" && statusVital === true) {
      setShowVitals(true);
    }
  }

  // Call Api Stream
  const handleStreamData = (cdiPromise) => {
    // Neu cdiPromise chua co ket qua tra ve thi sau 3s goi api nay
    Promise.race([cdiPromise, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
      if (result !== 'timeout') {
        console.log('API returned before timeout');
        return;
      }

      console.log('API question timed out. Calling API stream_data...');
      const params = {
        stream_mode: "cdi_edit_suggestion"
      }
      intervalIDRef.current = setInterval(() => {
        apiCaller(`/api/learn/case/stream_data/${id}/`, 'PUT', params).then(res => {
          const dataStream = res?.data;
          if (res?.status === 200) {
            setDataStream(dataStream);
          } else {
            setMessageErrDocumentation(messageDocumentation);
            // Xoa Stream
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }
        });
      }, 1000);
    });
  }

  //Show message for History
  const showMessageForHistoryTask = () => {
    const instructionHistory = TasksList?.Configurations?.find(o => o.code === "history");
    const contentAlert = instructionHistory?.instruction;

    if (contentAlert) {
      toast.success(<Toasts data={{ Message: contentAlert }} />, {
        autoClose: false,
        toastId: `instructionHistory`,
      });
    }

    //Kiểm tra case missing task, vì lúc này Question Null và chưa click task nào.
    handleCheckCase(id);
  };

  // Check Next Question and push data to reducer
  const handlePushChatRecordWileHasQuestion = (data) => {
    //Push Item to Array Chat Record
    const newMessage = {
      "Channel": "Instructor",
      "Role": "Instructor",
      "AvatarURL": "",
      "Type": "message",
      "Content": data?.Prompt,
      "QuestionId": data?.Id,
      "FullQuestionResponse": data || null // Để show Email Template
    }
    dispatch({
      type: questionConstants.UPDATE_CHAT_RECORD_FOR_QUESTION,
      payload: newMessage,
    });
  }

  //Chat - Add Record (Instructor Channel)
  const hanldeAddRecordToChanel = (data) => {
    const contentOfInstructor = data?.Prompt;
    const params = {
      "Action": "AddData",
      "CaseId": id,
      "TimePointId": CurrentTimePointId,
      "Data": {
        "Channel": "Instructor",
        "Role": "Instructor",
        "AvatarURL": "",
        "Type": data?.Type,
        "Content": contentOfInstructor,
        "QuestionId": data?.Id
      }
    }
    dispatch(atcAddRecordToChanel(params));
  }

  //Call api Next Question (Khi Click Got It)
  async function handleBeginLearner() {
    callApiCheckQuestionWithTask(id, isTask, ["welcome"]);
  };

  // Hanlde Close Modal Advance
  const hanldeToggleAdvance = (name) => {
    setIsOpenAdvance(prevState => ({ ...prevState, [name]: !prevState[name] }));
  }

  // Call Api Va Show Modal
  const callApiCheckDocumentation = () => {
    // Reset
    setCDIEditSuggestion(null);
    setDataStream(null);

    const params = {
      "CaseId": id,
      "TimePointId": CurrentTimePointId,
      "Action": "CheckDocumentation",
      "Text": stateDocumentationText
    }

    setLoading((prevState) => ({ ...prevState, CDIEditSuggestion: true }));
    // Start loading the first stage
    setTimeout(loadNextStage, stages[currentStage].duration);

    const CDIResponse = apiCaller(`/api/learn/documentation/`, "POST", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        setLoading((prevState) => ({ ...prevState, CDIEditSuggestion: false }));
        setCDIEditSuggestion(data);

        // Xoa Stream
        clearInterval(intervalIDRef.current);
        intervalIDRef.current = null;
      } else {
        setLoading((prevState) => ({ ...prevState, CDIEditSuggestion: false }));
        setMessageErrDocumentation(messageDocumentation);
      }
    });
    handleStreamData(CDIResponse);
  }

  //Them Class de xoa hieu ung
  const handleOverideEffectMenu = () => {
    setOverRideEffect(true);
  };

  //Xoa class de hien thi hieu ung
  const handleRemoveOverideEffectMenu = () => {
    setOverRideEffect(false);
  };

  // Handle Click Next Tour 
  const handleClickNextTour = (nextTour) => {
    taskNameNextTour(nextTour);
  }

  //Trigger câu hỏi điều kiện để mở view question
  const handleCheckQuestionNextWhenChat = () => {
    callApiCheckQuestionWithTask(id, 'chat');
  }

  //Handle Close ERM fixed
  const handleCloseErmFixed = (_, type) => {
    // Tắt view ERM Fixed
    if (type === "closeTask") {
      setStateType((prevState) => ({ ...prevState, PatientErmFixed: false }));
    }
  }

  //handle Open Erm Fixed
  const handleOpenErmViewFixed = () => {
    setStateType((prevState) => ({ ...prevState, PatientErmFixed: true }));
  }

  const handleChatFeedback = (type, forceState) => {
    setIsChatFeedbackOpen((prevState) => {
      if (forceState !== undefined) {
        // Directly set the state based on the forceState parameter
        return { ...prevState, [type]: forceState };
      }
      // Toggle the state if forceState is not provided
      return { ...prevState, [type]: !prevState[type] };
    });
  };

  // Handle Automated Feedback
  const handleAutomatedFeedback = (AutomatedFeedbackTrigger) => {
    if (AutomatedFeedbackTrigger) {
      if (isChatFeedbackOpen?.isChatFeedbackOpen) {
        // Đóng Chat Feedback
        handleChatFeedback("chatFeedbackClick", false);

        // Gửi action Redux để mở Chat Feedback trên thiết bị di động
        if (isMobile) {
          dispatch(toggleChatFeedbackMobile(false));
        }

        // Đặt biến trạng thái để mở lại sau
        setShouldReopenFeedback(true);
      } else {
        // Nếu Chat Feedback không mở, mở ngay lập tức
        handleChatFeedback("chatFeedbackClick", true);

        // Gửi action Redux để mở Chat Feedback trên thiết bị di động
        if (isMobile) {
          dispatch(toggleChatFeedbackMobile(true));
        }
      }
    }
  };

  // useEffect để mở lại Chat Feedback sau khi đã đóng
  useEffect(() => {
    if (shouldReopenFeedback) {
      handleChatFeedback("chatFeedbackClick");

      // Gửi action Redux để mở Chat Feedback trên thiết bị di động
      if (isMobile) {
        dispatch(toggleChatFeedbackMobile(true));
      }

      // Đặt lại biến trạng thái sau khi mở lại
      shouldReopenFeedback(false);
    }
  }, [shouldReopenFeedback, isMobile, dispatch]);

  // const handleCloseVideo = () => {
  //   dispatch(atcUpdateLearnerDemo({ LearnerDemoShowed: true }));
  //   setModal((prevState) => ({ ...prevState, LearnerDemo: false }));
  // };

  //Check Task hiện tại khi click vào instructor
  const handleCheckVirtualPreceptorStatus = (CurrentFrontSessionId) => {
    dispatch(LoadVirtualPreceptorStatus(CurrentFrontSessionId))
      .then(response => {
        const isTaskCurrent = response?.data?.task;
        setIsTask(isTaskCurrent);

        // Nhận task từ api và kiểm tra còn question hay không ?
        callApiCheckQuestionWithTask(id, isTaskCurrent);
      })
      .catch(error => {
        // Handle the error
        console.error('Error loading status:', error);
      });
  }

  const caseResponse = dataResponse?.Case;
  const timeLine = caseResponse?.Timeline;

  //Lay chieu cao cua hinh background tru di chieu cao cua 2 tab tren cung
  const heightPatientMedRecord = sizePatientPhoto?.height - 130;
  let isLockViewQuestion = questionData?.data?.Question !== null && isTask === "history";
  let isFullScreenViewWithChat = stateType?.chat || stateType?.question; //Kiểm tra view chat, question dang mở

  // Xử lý cho mobile
  const assistantAvatar = (
    <AssistantAvatarSmall
        {...props}
        caseData={caseResponse || {}}
        handleItemClick={(type) => {
          if (type === "question") {
            handleToogleQuestionView("question");
          } else if(type === 'chat') {
            //Gửi type chatFullFixed để check không toast missing Task
            handleExamMenu(type, isViewChatFullScreen);
          }
        }}
        handleClickNextTour={handleClickNextTour}
        handleOpenErmViewFixed={handleOpenErmViewFixed}
        taskNexTour={taskNexTour}
        isCurrentTask={isTask}
        onChangeSwithChat={(e) => {
            const { value } = e.target;
            setSwitchChat(value);
        }}
        stateType={stateType}
        isFullScreenViewWithChat={isFullScreenViewWithChat}
        isChatFeedbackOpen={isChatFeedbackOpen}
        endChatFeedbackData={endChatFeedbackData}
        alertPendingQuestion={alertPendingQuestion}
        handleChatFeedback={() => handleChatFeedback("chatFeedbackClick")}
        handleCloseInstructorFeedback={() => handleChatFeedback("endChatFeedback")}
        handleCheckVirtualPreceptorStatus={handleCheckVirtualPreceptorStatus}
    />
  );

  return (
    <React.Fragment>
      <div className="-mainPage" steps={TourData || []}>
        <div className="position-relative container-fluid">
          <div className={`learner-main-outer position-relative ${isMobile ? "is-mobile" : ""}`} id={`${isLockViewQuestion ? "lock-event-forquestion" : "unlock-event-forquestion"}`}>
            {(loadImage || caseData?.isLoading) && <LoaderPage />}
            {stateType?.welcome && (
              <InteractAssistantBegin
                {...props}
                handleBeginLearner={handleBeginLearner}
                isLoadingGotIt={isLoading?.btnBeginQuestion}
                content={dataClinicalStatus?.History}
                stateType={stateType}
              />
            )}
            {/* Sử dụng cho PC và cả mobile, nhưng bị ẩn 1 số thành phần dựa vào ID */}
            <div id={isMobile ? "fixed-instructor-header" : undefined}>
                {assistantAvatar}
            </div>
            {/* /CHAT - View nay dung chung cho teach, patient */}
            <QuestionFullFixed
              {...props}
              stateType={stateType}
              isCurrentTask={isTask}
              isShowChatFullScreen={logicShowChat}
            >
              <ChatInterface
                {...props}
                data={caseResponse || {}}
                handleExamMenu={handleExamMenu}
                handleOverideEffectMenu={handleOverideEffectMenu}
                switchChat={switchChat}
                ChatMode={ChatMode}
                // handeOpenInstructionForChat={() => {
                //   setInstructionChat(true);
                // }}
                handleCheckQuestionNextWhenChat={handleCheckQuestionNextWhenChat}
                handleChatFeedback={() => handleChatFeedback("chatFeedbackClick")}
  
                //EndChat - Lưu data khi mode là InstructorFeedback
                handleOpenInstructorFeedback={(data) => {
                  setEndChatFeedback(data);
                  handleChatFeedback("endChatFeedback");
                }}
                endChatFeedbackData={endChatFeedbackData}
  
                // Su dung cho view Question
                questionData={questionData}
                isCurrentTask={isTask}
                handleFetchInstruction={handleFetchInstruction}
                handleButtonMessageChat={handleExamMenu}
                stateType={stateType}
                handleClickBtnNextTimePoint={handleClickBtnNextTimePoint}
                isViewChatFullScreen={isViewChatFullScreen}
                handleOpenErmViewFixed={handleOpenErmViewFixed}
                handleCheckChatFeedback={(data, typeChat) => {
                  const AutomatedFeedbackTriggerCount = dataResponse?.Case?.ChatSettings?.AutomatedFeedbackTriggerCount;
                  const AutomatedFeedbackTrigger = dataResponse?.Case?.ChatSettings?.AutomatedFeedbackTrigger;
                  let chatRecordReducerCount;
                  if (typeChat === 'FreeText') {
                    // If typeChat is FreeText, filter based on Role
                    chatRecordReducerCount = data?.filter(record => record.Role === "User");
                  } else if (typeChat === 'Voice') {
                    // If typeChat is Voice, assume data is an array of strings
                    chatRecordReducerCount = data; // No filtering needed, use data directly
                    dispatch(NumberFeedbackTrigger(data));
                  }
  
                  // Kiểm tra tổng số record và gọi phản hồi sau mỗi AutomatedFeedbackTriggerCount (ví dụ sau 3 record)
                  if (chatRecordReducerCount?.length % parseFloat(AutomatedFeedbackTriggerCount) === 0) {
                    // Gọi phản hồi tự động nếu điều kiện thỏa mãn
                    handleAutomatedFeedback(AutomatedFeedbackTrigger);
                  }
                }}
              >
                {/* Sử dụng cho Mobile */}
                {isMobile && assistantAvatar}
              </ChatInterface>
            </QuestionFullFixed>
            <LearnMainLayout>
              <LearnerTop {...props} />
              <LearnerGridParent>
                <LearnerGridLeftParent {...props} sizePatientPhoto={sizePatientPhoto}>
                  {/* Check Image and Case Done */}
                  {/* Neu Show Documentation thi show luon box nay */}
                  <AssistantDocumentation
                    {...props}
                    sizePatientPhoto={sizePatientPhoto}
                    stateDocumentationText={stateDocumentationText}
                    isOpenFeedback={modal?.AssistantDocumentation}
                    isLoading={isLoading?.CDIEditSuggestion}
                    stateCDIEditSuggestion={stateCDIEditSuggestion}
                    stateStream={stateStream}
                    loadingText={loadingText}
                    messageErrDocumentation={messageErrDocumentation}
                  />
                  {isShowVitals && <VitalsignsOverlay {...props} />}
                  <PatientImageBackground
                    {...props}
                    handleOverideEffectMenu={handleOverideEffectMenu}
                    getSizeWrap={getSizeWrap}
                    sizePatientPhoto={sizePatientPhoto}
                    isTask={isTask}
                    checkImageLoad={
                      (imageWidth, imageHeight) => {
                        setLoadImage(false);
                        setSizePatientPhoto({
                          height: imageHeight,
                          width: imageWidth,
                        });
                      }
                    }
                  />
  
                  {/* /PN ORDERS */}
                  {stateType?.pn && (
                    <div className="interactAssistant bg-white">
                      <PNOrderSection
                        dataCase={caseResponse}
                        handleOverideEffectMenu={handleOverideEffectMenu}
                      />
                    </div>
                  )}
  
                  {/* /ACTIONS */}
                  {stateType?.actions && (
                    <ActionsMain
                      {...props}
                      data={actionsData}
                      handleExamMenu={handleExamMenu}
                      handleOverideEffectMenu={handleOverideEffectMenu}
                      heightPatientMedRecord={heightPatientMedRecord}
                      isCurrentTask={isTask}
                      stateType={stateType}
                    />
                  )}
  
                  {/* /ADVANCE */}
                  <InteractAdvanceCompleted
                    {...props}
                    isOpen={isOpenAdvance?.compeletedTimepoint}
                    handleExamMenu={handleExamMenu}
                    handleOverideEffectMenu={handleOverideEffectMenu}
                    data={advanceResponse || {}}
                    questionData={questionData}
                    caseResponse={caseResponse}
                    timeLine={timeLine}
                    hanldeToggleAdvance={hanldeToggleAdvance}
                  />
  
                  {/* ucr */}
                  <UCREndCase
                    {...props}
                    isOpen={isOpenAdvance?.ucrTesting}
                    handleExamMenu={handleExamMenu}
                    data={advanceResponse || {}}
                    questionData={questionData}
                    caseResponse={caseResponse}
                    timeLine={timeLine}
                    hanldeToggleAdvance={hanldeToggleAdvance}
                  />
  
                  {/* Default - weitzman, EndCase With API */}
                  <DefaultEndCase
                    {...props}
                    isOpen={isOpenAdvance?.defaultEndCase || isOpenAdvance?.weitzman}
                    handleExamMenu={handleExamMenu}
                    data={advanceResponse || {}}
                    questionData={questionData}
                    caseResponse={caseResponse}
                    timeLine={timeLine}
                    hanldeToggleAdvance={hanldeToggleAdvance}
                  />
  
                  <ModalCompletedCase
                    {...props}
                    isOpen={isOpenAdvance?.infoCompeleted}
                    handleOverideEffectMenu={handleOverideEffectMenu}
                    hanldeToggleAdvance={hanldeToggleAdvance}
                  />
  
                  <CongratulationsTimePoint
                    {...props}
                    isOpen={isOpenAdvance?.nextTimepoint && required_user_confirmation} // Hidden Modal if required_user_confirmation is false
                    handleExamMenu={handleExamMenu}
                    handleOverideEffectMenu={handleOverideEffectMenu}
                    data={advanceResponse || {}}
                    questionData={questionData}
                    caseResponse={caseResponse}
                    timeLine={timeLine}
                    hanldeToggleAdvance={hanldeToggleAdvance}
                  />
  
                  <ThankYouFeedback
                    {...props}
                    isOpen={isOpenAdvance?.ThankYou}
                    handleExamMenu={handleExamMenu}
                    handleOverideEffectMenu={handleOverideEffectMenu}
                    data={advanceResponse || {}}
                    questionData={questionData}
                    caseResponse={caseResponse}
                    timeLine={timeLine}
                    hanldeToggleAdvance={hanldeToggleAdvance}
                  />
  
                  {/* Phan Nay cho Med Record */}
                  {/* /TAB */}
                  {(stateType?.labs || stateType?.PatientErm || stateType?.PatientErmFixed || statusTabActiveMedicalRecord) && (
                    //hidden when show chat actions
                    <PatientErmContainer
                      {...props}
                      actionsData={actionsData}
                      data={dataResponse}
                      dataTour={TourData || []}
  
                      // Orders Click
                      handleExamMenu={isFullScreenViewWithChat ? handleCloseErmFixed : handleExamMenu}
                      handleOverideEffectMenu={handleOverideEffectMenu}
                      heightPatientMedRecord={heightPatientMedRecord}
                      stateType={stateType}
                      isCurrentTask={isTask}
                      statusTabActiveMedicalRecord={statusTabActiveMedicalRecord}
                    // activeDefaultTab={statusTabActiveMedicalRecord}
                    />
                  )}
                </LearnerGridLeftParent>
                <LearnerGridRight>
                  <MenuTaskColumn
                    {...props}
                    dataInterface={stateInterFace}
                    handleExamMenu={handleExamMenu}
                    resCase={dataResponse}
                    dataTour={TourData || []}
                    overRideEffect={overRideEffect}
                    handleOverideEffectMenu={handleOverideEffectMenu}
                    handleRemoveOverideEffectMenu={handleRemoveOverideEffectMenu}
                    isCurrentTask={isTask}
                    stateType={stateType}
                    handleClickNextTour={handleClickNextTour}
                    taskNexTour={taskNexTour}
                    activeMenuTask={activeMenuTask}
                    disableNextTimepoint={disableNextTimepoint}
                    handleClickBtnNextTimePoint={handleClickBtnNextTimePoint}
                    isLoading={isLoading}
                  />
                </LearnerGridRight>
              </LearnerGridParent>
              {/* ProgressBarTimePoint for Modal (Tryền các props có trong MenuTaskColumn tránh miss) */}
              {isMobile &&
                <ProgressBarTimePoint
                  {...props}
                  dataInterface={stateInterFace}
                  handleExamMenu={handleExamMenu}
                  resCase={dataResponse}
                  dataTour={TourData || []}
                  overRideEffect={overRideEffect}
                  handleOverideEffectMenu={handleOverideEffectMenu}
                  handleRemoveOverideEffectMenu={handleRemoveOverideEffectMenu}
                  isCurrentTask={isTask}
                  stateType={stateType}
                  handleClickNextTour={handleClickNextTour}
                  taskNexTour={taskNexTour}
                  activeMenuTask={activeMenuTask}
                  disableNextTimepoint={disableNextTimepoint}
                  handleClickBtnNextTimePoint={handleClickBtnNextTimePoint}
                  isLoading={isLoading}
                />
              }
            </LearnMainLayout>
          </div>
        </div>
  
        {/* Advance - User Feedback */}
        <ModalUserFeedback
          {...props}
          isOpen={isOpenAdvance.UserFeedback}
          hanldeToggleAdvance={hanldeToggleAdvance}
        />
  
        {/* Advance - Chat Wrap-up */}
        {isShowChatWrapUp &&
          <ModalChatWrapUp
            {...props}
            isOpen={isShowChatWrapUp}
            typeModal={'ChatWrapUp'}
            handleContinueCase={() => {
              //Tắt modal
              setShowChatWrapUp(false);
  
              //Tiếp tục thực hiện logic show modal advance
              //Check xem kết thúc case chưa ?
              checkViewForAdvance();
            }}
          />
        }
  
        {/* Phan Cu---------------- */}
        <ModalHumanModel
          {...props}
          isOpen={modal.HumanModel}
          useHumanModel={stateInterFace?.UseHumanModel}
          handleOverideEffectMenu={handleOverideEffectMenu}
          toggle={() => toggle("HumanModel")}
        />
        <ModalMedicalRecord
          {...props}
          timeLine={timeLine}
          isOpen={stateType?.documentation}
          stateDocumentationText={stateDocumentationText}
          handleDocumentationText={handleDocumentationText}
          handleShowDocumentation={() => {
            setModal((prevState) => ({ ...prevState, AssistantDocumentation: true }));
            callApiCheckDocumentation();
          }}
          handleExamMenu={handleExamMenu}
          isLoading={isLoading?.CDIEditSuggestion}
          isOpenFeedback={modal?.AssistantDocumentation}
        />
        {/* <MemberFixed
          {...props}
          caseData={caseResponse || {}}
          handleOverideEffectMenu={handleOverideEffectMenu}
        /> */}
      </div>
      
      {isOpenChatFBMobile && <ChatFeedBackPopup {...props} />}
      {isOpenExamBMobile && <ExamPopupMobile {...props} />}
      {isOpenEMRMobile &&
        <NarativePopup {...props} />
      }
    </React.Fragment>
  );
}

export default StandardScreenMain;