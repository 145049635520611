/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import check from '@images/teachV2/check.svg'
import nonCheck from '@images/teachV2/non-check.svg'
import { Icon } from '@components/common'
import { loadNextStepWizard } from "@actions";
import { useDispatch, useSelector } from "react-redux";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function ChoiceCommon(props) {
  const { title, subtitle, dataForm, setValue, handleNext, watch, parent, getValues, selectTypeFlow } = props;
  const dispatch = useDispatch()
  const dataNextWizard = useSelector((state) => state.loadNextStepWizard || {});
  const [dataChoice, setDataChoice] = useState()
  console.log("🚀 ~ handleNextAction ~ watch(dataForm?.field) :", watch(dataForm?.field) )

  const handleChoose = (value) => {
    setDataChoice(value)
    setValue(dataForm?.field, value)
  }

  const handleNextAction = () => {
    if (watch(dataForm?.field) !== "none" && watch(dataForm?.field) !== false && parent["Sub-Steps"]?.length !== 0) {
      const values = getValues();
      dispatch(loadNextStepWizard({
        action: "next_step",
        current_step_id: parent?.StepId,
        ...values,
      }, 1, dataForm?.field))
    } else {
      handleNext()
    }
  }

  useEffect(() => {
    if (dataForm) {
      dataForm?.items?.forEach((data) => {
        if (data?.default) {
          setDataChoice(data?.value)
          setValue(dataForm?.field, data?.value)
        }
      })
    }
  }, [dataForm])

  return (
    <WrapCommon>
      {dataNextWizard?.dataReference && dataForm?.field === "reference_source" ? (
        selectTypeFlow(dataNextWizard?.dataReference?.Contents, dataNextWizard?.dataReference)
      ) : dataNextWizard?.dataMedia && dataForm?.field === "use_media" ? (
        selectTypeFlow(dataNextWizard?.dataMedia?.Contents, dataNextWizard?.dataMedia)
      ) : (
        <>
          <div className="form-area">
            <div className="form-area__content">
              <h1>{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: subtitle ? subtitle : "" }} />
            </div>
            <div className="form-area__choice">
              {dataForm?.items?.map((data, index) => (
                <div 
                  key={index}
                  className={`form-area__choice-item ${dataChoice === data?.value}`}
                  onClick={() => handleChoose(data?.value)}
                >
                  <div className="form-area__choice-item--title">
                    <h1>{data?.title}</h1>
                    <Icon src={dataChoice === data?.value ? check : nonCheck} />
                  </div>
                </div>
              ))}
            </div>
          </div> 
          <div className='btn-form'>
            <ButtonSubmitCommon {...props} handleNext={handleNextAction}/>
          </div>     
        </>
      )}
    </WrapCommon>
  )
}
