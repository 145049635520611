import React, { useState } from 'react'
import { Icon } from '@components/common'
import BookClosed from '@images/teachV2/book-closed.svg'

const ScriptElement = (props) => {
  const { dataCaseScript, lineRefs, refDefault, refImage } = props
  const [active, setActive] = useState(0)

  const scrollToElement = (ref, index) => {
    setActive(index)
    if (ref.current) {
      window.scrollTo({ 
        behavior: 'smooth',
        top: ref.current.getBoundingClientRect().top + window.scrollY - 120,
      });
    }
  };

  // const handleScroll = () => {
  //   let newActiveTab = null;
    
  //   lineRefs?.current?.forEach((section, index) => {
  //     const rect = section.current.getBoundingClientRect();
  //     if (rect.top <= window.innerHeight / 6) {
  //       newActiveTab = index+1;
  //     }
  //   });
  //   if (newActiveTab) {
  //     setActive(newActiveTab)
  //   } else {
  //     setActive(0)
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className='scriptElement'>
      <div className="scriptElement__header w-100">
        <div className="icon-book">
          <Icon src={BookClosed} />
        </div>
        <p>Case Scenario Elements</p>
      </div>
      <div className="scriptElement__list w-100">
        <div className={`scriptElement__list-item ${active === 0 ? "active" : ""}`} onClick={() => scrollToElement(refDefault, 0)}>
          <div className="item-name">
            <span></span>
            <p>Learning Points</p>
          </div>
          {/* <div className='item-loading'></div> */}
        </div>
        {dataCaseScript?.case_sections?.map((section, index) => (
          <div key={index} className={`scriptElement__list-item ${active === index + 1 ? "active" : ""}`} onClick={() => scrollToElement(lineRefs.current[index], index+1)}>
            <div className="item-name">
              <span></span>
              <p>{section?.name}</p>
            </div>
            {/* <div className='item-loading'></div> */}
          </div>
        ))}
        {dataCaseScript?.case_sections?.length && (
          <div className={`scriptElement__list-item ${active === dataCaseScript?.case_sections?.length + 1 ? "active" : ""}`} onClick={() => scrollToElement(refImage, dataCaseScript?.case_sections?.length + 1)}>
            <div className="item-name">
              <span></span>
              <p>Patient Profile Image</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ScriptElement
