import React, { useState } from "react";
import { Button, Spinner } from 'reactstrap';
import { useDispatch } from "react-redux";
import { actActiveTabsMedicalRecord } from "@actions";

import styled from 'styled-components';

const ListOrder = styled.ul`
  height: calc(100vh - 410px);
  overflow-y: auto;
`;

function CurrentOrderList(props) {
  const { data, handleChangeItem, handleExamMenu, isCurrentTask } = props && props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleProceed = () => {
    setLoading(true);
    setTimeout(() => {
      handleExamMenu(isCurrentTask, "closeTask");
      dispatch(actActiveTabsMedicalRecord(null));
      setLoading(false);
    }, 2000);
  };

  return (
    <div className='h-100'>
      <div className="pd-order-box bg-white --border-l1-primary">
        <ListOrder>
          {data?.map((item, index) => (
            <li className="lsOrder__item" key={index} style={{ cursor: 'auto' }}>
              <span className="lsOrder__item--title">{item?.Name}</span>
              <span
                className="lsOrder__item--icon icon-remove text-7 text-danger"
                data-name={item?.Name}
                data-record={item?.OrderRecordId}
                data-id={item?.Id}
                onClick={(e) =>
                  handleChangeItem(e, item, "remove", '')
                }
                style={{ cursor: 'pointer' }}
              ></span>
            </li>
          ))}
          {data?.length === 0 && <p className="message-sp text-center">select and add Lab tests to your order</p>}
        </ListOrder>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          className="button-cta btn-orange m-3"
          onClick={handleProceed}
          disabled={data?.length === 0 || loading} 
        >
          {loading && <Spinner className="mr-2" size="sm" />}
          Proceed with Selected Orders
        </Button>
      </div>
    </div>
  );
}

export default CurrentOrderList;