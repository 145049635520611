import React from 'react';
import styled from 'styled-components';
import topTaskIcon from '@images/learnerv2/icon-top-task-sp.svg';
import IconCloseChat from '@images/learnerv2/icon-close-sp.svg';
import { ExamListData } from "../ExamTask";
import { toggleExamMobile } from "@actions";
import { useDispatch, useSelector } from "react-redux";

// Styled Components
const ExamPopup = styled.div`
    width: 100%;
    height: 80%;
    padding: 8px 8px;
    gap: 8px;
    background-color: #7CD9FD;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(12px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${(props) => (props.hasQuestion ? 14000 : 16000)};
    height: 100vh;
    padding: 16px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

const CloseButton = styled.button`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 150px;
  background: #FFF;
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 12px;
`;

const InnerWrapper = styled.div`
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    background: #7CD9FD;
    width: 100%;
    border-radius: 12px;
    position: relative;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    &:before {
        content: "";
        background-image: url(${topTaskIcon});
        width: 84px;
        height: 31px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
`;

const ExamPopupMobile = () => {
    const dispatch = useDispatch();
    const questionData = useSelector((state) => state.question || {});
    const QuestionResponse = questionData?.data?.Question;

    // Handle Close
    const handleClose = () => {
        dispatch(toggleExamMobile(false));
    };

    return (
        <ExamPopup hasQuestion={QuestionResponse !== null}>
            <div className="text-right">
                <CloseButton onClick={handleClose}>
                    <img src={IconCloseChat} alt="Close" />
                </CloseButton>
            </div>
            <InnerWrapper>
                <ExamListData />
            </InnerWrapper>
        </ExamPopup>
    );
};

export default ExamPopupMobile;