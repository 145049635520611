import React from 'react';

function OrderList(props) {
    const { data, value, handleChangeItem } = props && props;
    const totalHeight = `calc(100vh - 330px)`; // Properly setting the height value
    return (
        <div className='isShowScroll' style={{ maxHeight: totalHeight }}>
            <ul className="lsOrder">
                {data?.map((item, index) => (
                    <li
                        key={index}
                        className="lsOrder__item"
                        onClick={(e) =>
                            handleChangeItem(e, item, "add", value)
                        }
                        data-category={value?.Category}
                        data-name={item?.Name}
                        data-id={item?.Id}
                    >
                        <span className="lsOrder__item--title">{item?.Name}</span>
                        <span className="lsOrder__item--icon icon-arrow-right"></span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default OrderList;