import React, { useState } from 'react'
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'
import { Button } from 'reactstrap'
import Copy from '@images/teachV2/copy.svg'
import ReactTooltip from 'react-tooltip'
import Feedback from '@images/teachV2/feedback.svg'
import GenerateImage from '@images/teachV2/generate-img.svg'
import McqWaiting from '@images/teachV2/waiting-mcq.gif'
import Reload from '@images/teachV2/reload.svg'
import {apiCaller} from "@utils";
import { useDispatch } from 'react-redux'
import { teachV2Constants } from "@constants";
import GenrateFail from '@images/teachV2/gen-failed.gif'

export default function GenerateItemImage(props) {
  const { dataCaseScript, dataRef } = props
  const dispatch = useDispatch();

  const [showData, setShowData] = useState(true)
  const [showError, setShowError] = useState("")
  const [loadingGenerate, setLoadingGenerate] = useState(false)

  const handleShowData = () => {
    setShowData(!showData)
  }

  const handleGenerateImage = (e) => {
    e.preventDefault()
    setLoadingGenerate(true)
    
    const params = {
      action_type: "generate_case_image",
      id: dataCaseScript?.id,
    };
    apiCaller(`/api/medicalcase/functional/`, "POST", params).then((res) => {
      const resData = res?.data;
      if (res?.status === 200) {
        dispatch({ type: teachV2Constants.LOAD_CASE_SCRIPT_SUCCESS, payload: resData });
        setLoadingGenerate(false);
        setShowError("")
      } else {
        console.log("error");
        setShowError(res?.data?.error || "Oops! Something went wrong, it look like there are no questions generated at the moment.")
        setLoadingGenerate(false);
      }
    });
  }

  const handleCopy = async (e) => {
    e.preventDefault()
    try {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Thiết lập để hỗ trợ CORS
      img.src = dataCaseScript?.image_url;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(async (blob) => {
          const item = new ClipboardItem({ 'image/png': blob });
          await navigator.clipboard.write([item]);
          alert('Hình ảnh đã được sao chép vào clipboard!');
        }, 'image/png');
      };

      img.onerror = () => {
        console.log("Không thể sao chép ảnh");
      };
    } catch (error) {
      console.error('Có lỗi xảy ra: ', error);
    }
  }

  // const handleFeedback = (title, dataRef) => {
  //   toggleModal("modalGiveFeedback")
  //   const text = title ? title.concat(" \n", dataRef?.current?.innerText) :  "Learning Points:".concat(" \n", dataRef?.current?.innerText)
  //   setDataFeedback(text)
  // }

  return (
    <div className='generateItem'>
      <div className="generateItem__header w-100">
        <div className="generateItem__header-title">
          <div className="icon-show" onClick={handleShowData}>
            {showData ? <Icon src={Up}/> : <Icon src={Down}/>}
          </div>
          <p>Patient Profile Image</p>
          </div>        
        <div className="generateItem__header-action">
          {!dataCaseScript?.image_url ? (
            <Button
              className="d-flex align-items-center btn-image"
              onClick={(e) => handleGenerateImage(e)}
            >
              <Icon src={GenerateImage} />
              <span>Generate</span>
            </Button>
          ) : (
            <>
              <Button 
                className="action-copy" 
                onClick={(e) => handleGenerateImage(e)}
              >
                <Icon src={Reload}/>
              </Button>
              <Button 
                data-tip
                data-for={`copy-image`}
                className="action-copy" 
                onClick={(e) => handleCopy(e)}
              >
                <Icon src={Copy} stroke="#98A2B3"/>
              </Button>
              <ReactTooltip id={`copy-copy-image`} place='top' effect='solid' className="case-tooltip">
                <span>Copy this Section</span>
              </ReactTooltip>
              <Button 
                data-tip
                data-for={`feedback-image`}
                className="action-copy" 
                // onClick={() => handleFeedback(titleGenerate, dataRef)}
                onClick={(e) => e.preventDefault()}
              >
                <Icon src={Feedback} stroke="#98A2B3"/>
              </Button>
              <ReactTooltip id={`feedback-image`} place='top' effect='solid' className="case-tooltip">
                <span>Give Feedback</span>
              </ReactTooltip>
            </>
          )}
        </div>
      </div>
      {showData && (
        <div ref={dataRef} className='w-100'>
          {loadingGenerate ? (
            <div className="generateItem__loading">
              <img className='img-wait' src={McqWaiting} alt="mcq" />
              <div className="generateItem__loading-content">
                <h1>Generating The Patient Image</h1>
                <p>Just a moment... your patient image is <br/> on the way!</p>
              </div>
            </div>
          ) : (
            !dataCaseScript?.image_url ? (
              showError ? (
                <div className="generateItem__failed">
                  <div className="d-flex justify-content-center">
                    <img src={GenrateFail} alt="Oops" />
                  </div>
                  <p className="text-center mt-3">{showError}</p>
                </div>
              ) : (
                <span className="generateItem__explain">Click ‘Generate’ to create an image for this patient. <br />This will be used as the profile picture in the converted interactive module.</span> 
              )
            ) : (
              <div className="generateItem__image">
                <img src={dataCaseScript?.image_url} alt="default" />
              </div>
            )
          )}
        </div>
      )}
    </div>
  )
}
