import React, { useState } from 'react';

// reactstrap
import { Input } from "reactstrap";

// Icon
import { Iconsax } from "@components-common";

//redux
import { useSelector } from "react-redux";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

// components
import { SendIcon } from "../FreeText";

function InputFreeText(props) {
  // props
  const { handleSubmitChoices, handleSubmitFreeText, isLoading, valueOptions, isMessageLoader, disabled } = props && props;

  // Check Lock Elements
  const isChatPending = useSelector((state) => state.isChatPending || {});
  const isLock = isChatPending?.isLock;

  // state
  const [value, setValue] = useState('');

  // send Api
  const handleSubmit = () => {
    if (value) {
      handleSubmitFreeText(value);
      setValue(''); // Clear the value after successful submit
    } else {
      handleSubmitChoices();
    }
  }

  // handle change
  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
  }

  // handle key press
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  }

  // Use screen size context
  const { isMobile } = useScreenSize();

  return (
    <div className="chatFooter">
      <div className="position-relative w-100">
        <Input
          name="answer"
          placeholder={`Enter your answer or ask me for help here...`}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={valueOptions || value}
          disabled={disabled || isLoading || isLock}
          autoComplete="off"
        />
        {isMobile && <SendIcon onClick={handleSubmit} />}
      </div>
      <button
        type="button"
        className="btn-send ml-2"
        onClick={handleSubmit}
        disabled={isLoading || isLock}
      >
        {isMessageLoader ? (
          <span className="mr-2 text-nowrap">
            {isMessageLoader}
            <span className="loading-dots"></span>
          </span>
        ) : (
          <span className="mr-2 text-nowrap">Send</span>
        )}
        {isLoading ? (
          <span className="spinner-border spinner-border-sm ml-2"></span>
        ) : (
          <Iconsax iconName="send" fill="#fff" />
        )}
      </button>
    </div>
  );
};

export default InputFreeText;