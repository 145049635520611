  import React from 'react';
  import styled from 'styled-components';
  import { Row, Col, FormGroup, Label, Button, FormFeedback } from 'reactstrap';
  import { Formik, Field, Form, ErrorMessage } from 'formik';
  import * as Yup from 'yup';

  //react-router-dom
  import { useHistory } from "react-router-dom";

  //components
  import { FormHeader } from "../AddUpdateFood";
  import { LoadingGenerateAI } from "../AIGenerationFood";

  //constants
  import { makansafeConstants } from "@constants";

  //react-redux
  import { useDispatch } from "react-redux";

  //utils
  import { apiCaller } from "@utils";

  // Styled Components
  const FormContainer = styled(Form)`
    max-width: 700px;
    margin: 40px auto;
    padding: 96px 0;
  `;

  const Heading = styled.h2`
    color: #101828;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.3;
  `;

  const Description = styled.p`
    color: #344054;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-bottom: 40px;
  `;

  const StyledButton = styled(Button)`
    margin-top: 48px;
    float: right;
    height: 48px;
  `;

  const validationSchema = Yup.object().shape({
    foodName: Yup.string()
      .trim()
      .required('Food name is required') // Validation yêu cầu
      .test('is-valid-food-name', 'Please enter a valid food name', value => value && value.length > 0), // Kiểm tra chuỗi rỗng
    description: Yup.string()
  });

  const AIGenerationFoodForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // Hàm xử lý khi submit form
    const handleFormSubmit = (values, { setSubmitting }) => {
      dispatch({ type: makansafeConstants.GENERATE_AI_IDDSI_FOOD_REQUEST });
      const params = {
        action_type: "generate_iddsi_content",
        food_name: values.foodName,
        description: values.description || "",
      };

      // Gọi API với các tham số đã chuẩn bị
      apiCaller(`/api/iddsi/functional/`, "POST", params, true)
        .then(res => {
          if (res?.status === 200) {
            dispatch({ type: makansafeConstants.GENERATE_AI_IDDSI_FOOD_SUCCESS, payload: res.data });

            // Save data to localStorage
            localStorage.setItem('generatedFoodData', JSON.stringify(res.data));

            history.push("/makansafe/add-food");
          } else {
            dispatch({ type: makansafeConstants.GENERATE_AI_IDDSI_FOOD_FAILURE, error: 'error' });
          }
        })
        .finally(() => setSubmitting(false)); // Ngừng trạng thái loading sau khi API trả về
    };

    return (
      <React.Fragment>
        <FormHeader title="Add New Food Item" />
        <Formik
          initialValues={{ foodName: '', description: '' }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting }) => (
            <div className='position-relative'>
              {/* Hiển thị LoadingGenerateAI khi đang submitting */}
              {isSubmitting && <LoadingGenerateAI />}

              {/* Hiển thị form khi không trong trạng thái loading */}
              {!isSubmitting && (
                <FormContainer>
                  <Heading>Enter Instructions for AI Generation</Heading>
                  <Description>
                    Please provide the information below for the AI to generate the food item.
                  </Description>

                  <Row>
                    <Col lg={12} sm={12}>
                      <FormGroup className="mt-2">
                        <Label className="ms-label" htmlFor="foodName">
                          Food Name <span className="text-danger">*</span>
                        </Label>
                        <Field
                          type="text"
                          name="foodName"
                          id="foodName"
                          placeholder="Enter the name of the food item (e.g., apple, carrot)"
                          className="form-control"
                        />
                        <ErrorMessage name="foodName" component={FormFeedback} className="d-block" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup className="mt-2">
                        <Label className="ms-label" htmlFor="description">
                          Description (Optional)
                        </Label>
                        <Field
                          as="textarea"
                          name="description"
                          id="description"
                          placeholder="Add a brief description or any specific details to help the AI generate an accurate result (optional)"
                          rows={5}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <StyledButton color="primary" type="submit" disabled={isSubmitting}>
                    Generate
                  </StyledButton>
                </FormContainer>
              )}
            </div>
          )}
        </Formik>
      </React.Fragment>
    );
  };

  export default AIGenerationFoodForm;